import React from "react";

import { Textarea, Input, MultiSelect } from "@mantine/core";
import { Button as MantineBtn, NumberInput, Select } from "@mantine/core";
import { PasswordInput as PwInput } from "@mantine/core";

export const numInputClasses = {
  defaultvariant:
    "w-full p-0 text-lg bg-transparent text-gray-600 focus:outline-none",
  input: "border-none bg-transparent",
  control: "border-none",
};

export const dropzoneClasses = {
  root: "border-none bg-transparent",
};

export const Error = ({ msg }) => (
  <p className="font-semibold text-red-500">{msg}</p>
);

const InputWrap = ({ label, borderThin = false, children }) => {
  return (
    <div
      className={`relative px-3 py-2 mt-3 border-black rounded text-left ${
        borderThin ? "border" : "border-2"
      }`}
    >
      <span className="text-md font-semibold text-zinc-900">{label}</span>

      {children}
    </div>
  );
};

const TextArea = ({ label, ...rest }) => {
  return (
    <InputWrap label={label}>
      <Textarea
        {...rest}
        variant="unstyled"
        // classNames={{
        //   defaultVariant:
        //     "w-full p-1 border-none bg-transparent text-gray-600 focus:outline-none",
        // }}
      />
    </InputWrap>
  );
};

const TextInput = ({ label, borderThin = false, ...rest }) => (
  <InputWrap borderThin={borderThin} label={label}>
    <Input
      {...rest}
      variant="unstyled"
      classNames={{
        defaultvariant:
          "w-full p-0 text-lg border-none bg-transparent text-gray-600 focus:outline-none",
      }}
    />
  </InputWrap>
);

const PasswordInput = ({ label, borderThin = false, ...rest }) => (
  <InputWrap borderThin={borderThin} label={label}>
    <PwInput
      {...rest}
      variant="unstyled"
      classNames={{
        defaultvariant:
          "w-full p-0 text-lg border-none bg-transparent text-gray-600 focus:outline-none",
      }}
    />
  </InputWrap>
);

const NumInput = ({ label, ...rest }) => (
  <InputWrap label={label}>
    <NumberInput {...rest} min={0} classNames={numInputClasses} />
  </InputWrap>
);

const SelectSingle = ({ label, ...rest }) => (
  <InputWrap label={label}>
    <Select
      {...rest}
      variant="unstyled"
      classNames={{
        defaultvariant:
          "w-full p-0 border-none bg-transparent text-gray-600 focus:outline-none",
      }}
    />
  </InputWrap>
);

const SelectMultiple = ({ label, ...rest }) => (
  <InputWrap label={label}>
    <MultiSelect
      {...rest}
      variant="unstyled"
      classNames={{
        value: "bg-gray-200",
      }}
    />
  </InputWrap>
);

const Button = ({ label, bordered = false, gray = false, ...rest }) => (
  <MantineBtn
    {...rest}
    className={`px-5 py-2 rounded translate-x-2 transition-all transform ease-in-out duration-200 ${
      bordered && "border-b-4 border-2 font-black border-l-4"
    } ${gray ? "border-gray-300" : "border-black"}`}
  >
    {label}
  </MantineBtn>
);

const SubmitButton = ({ label, ...rest }) => (
  <Button
    {...rest}
    bordered
    type="submit"
    variant="subtle"
    color="teal"
    className="bg-teal-100"
    label={label ?? "Submit"}
  />
);

export {
  TextArea,
  TextInput,
  PasswordInput,
  SelectSingle,
  Button,
  NumInput,
  InputWrap,
  SelectMultiple,
  SubmitButton,
};
