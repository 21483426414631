import React from "react";
import { useState } from "react";
import { useLayoutEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import toast from "react-hot-toast";
import { Image, Button, Group } from "@mantine/core";
import { BiReset } from "react-icons/bi";

import ResetPassword from "components/auth/user/ResetPassword";
import LoadingDots from "components/misc/LoadingDots";
import axiosService from "utils/axios";
import forgotPasswordSvg from "images/svgs/forgot-password.svg";
import changePasswordSvg from "images/svgs/change-password.svg";
import mailSentSvg from "images/svgs/mail-sent.svg";
import { LogoLink } from "layouts/blocks/LogoBlock";
import { TextInput } from "components/misc/Inputs";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState("");

  //   console.log(searchParams.get("token"));

  useLayoutEffect(() => {
    let token = searchParams.get("token");
    if (token) {
      let url = "/password-reset/validate_token/";
      let data = { token };
      axiosService
        .post(url, data)
        .then((res) => {
          if (res.data.status === "OK") {
            setStatus("reset_password");
          }
        })
        .catch((_) => {
          navigate("/");
        });
    } else {
      setStatus("capture_email");
    }
  }, [navigate, searchParams]);

  const head = {
    capture_email: {
      title: "Forgot Password",
      info: "You can request the password reset link on your email.",
      img: forgotPasswordSvg,
    },
    link_sent: {
      title: "Reset Link Sent",
      info: "Please check spam folder if not found!",
      img: mailSentSvg,
    },
    reset_password: {
      title: "Reset Password",
      info: "Please submit a strong new password!",
      img: changePasswordSvg,
    },
  };

  const confirmLinkSent = () => {
    setStatus("link_sent");
  };

  return status ? (
    <div className="w-full min-h-screen flex flex-col items-center bg-gradient-vertical p-3">
      <div className="max-w-lg m-auto bg-white p-3 sm:p-8 sm:rounded-xl shadow shadow-slate-300 drop-shadow-2xl">
        <Group position="center">
          <LogoLink />
        </Group>
        {head[status] && (
          <>
            <h1 className="text-4xl font-bold mb-2 text-red">
              {head[status].title}
            </h1>
            <Image
              src={head[status].img}
              fit="contain"
              alt="Password Reset SVG"
            />
            <p className="text-left text-slate-600 border-l-2 border-red mt-2 pl-2">
              {head[status].info}
            </p>
          </>
        )}

        {status === "capture_email" && (
          <CaptureEmail confirm={confirmLinkSent} />
        )}
        {status === "reset_password" && (
          <ResetPassword token={searchParams.get("token")} />
        )}
      </div>
    </div>
  ) : (
    <LoadingDots visible />
  );
};

export default ForgotPassword;

const CaptureEmail = ({ confirm }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let url = "/accounts/password-reset/";
    let data = { email };

    axiosService
      .post(url, data)
      .then(() => {
        // console.log(res);
        // if (res.data.status === "OK") {
        confirm();
        // } else {
        //   toast.error("Unknown error!");
        //   setLoading(false);
        // }
      })
      .catch((err) => {
        if (err.response.data?.email) {
          toast(err.response.data.email);
        } else {
          toast.error("Unknown error while requesting password reset!");
        }
        setLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit} className="relative mt-3">
      {loading && <LoadingDots visible />}
      <div className="flex flex-col space-y-5">
        <TextInput
          size="md"
          label="Email address"
          name="email"
          type="email"
          placeholder="Enter account email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <Button
          disabled={email === ""}
          type="submit"
          size="md"
          variant="light"
          color="orange"
          className="text-white hover:bg-red bg-orange-500"
          rightIcon={<BiReset />}
        >
          Request Reset
        </Button>
      </div>
    </form>
  );
};
