import React, { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";

import LogoBlock from "layouts/blocks/LogoBlock";
import LanguageControl from "./LanguageControl";
import InstitutionControl from "./InstitutionSelect";
import { useInstitution } from "store/slices/institution";
import { useLoggedIn } from "store/slices/auth";

const navLinks = [
  {
    label: "UED",
    url: "/",
  },
  {
    label: "Schools",
    url: "/our-schools",
    institutions: ["school"],
  },
  {
    label: "Courses",
    url: "/our-courses",
    institutions: ["aina", "academy", "all"],
  },
  {
    label: "Blogs",
    url: "/blogs",
  },
];

const BHead = () => {
  const location = useLocation();
  const menuBtnRef = useRef();
  const menuRef = useRef();
  const isLoggedIn = useLoggedIn();
  const institution = useInstitution();

  useEffect(() => {
    menuRef.current.classList.add("hidden");
    menuBtnRef.current.classList.remove("close");
  });

  const toggleMenu = () => {
    if (menuBtnRef.current.classList.contains("close")) {
      menuRef.current.classList.add("hidden");
      menuBtnRef.current.classList.remove("close");
    } else {
      menuRef.current.classList.remove("hidden");
      menuBtnRef.current.classList.add("close");
    }
  };

  return (
    <div className="relative z-50 w-full h-24 px-8 pt-2 bg-white">
      <div className="container flex items-center justify-between h-full max-w-6xl mx-auto">
        <LogoBlock />

        <div
          id="nav"
          ref={menuRef}
          className="absolute top-0 left-0 sm:left-auto sm:right-0 hidden w-full sm:w-auto mt-20 lg:px-5 lg:block lg:relative lg:mt-0 lg:w-auto transition-all transform duration-300 delay-100"
        >
          <nav className="flex flex-col gap-y-3 lg:gap-y-0 items-start lg:items-center px-3 pt-6 pb-3 bg-white border-4 border-black lg:flex-row lg:bg-transparent lg:border-none lg:py-0 lg:h-auto mx-3 drop-shadow-lg">
            {navLinks.map((link, index) => {
              let linkItem = (
                <Link
                  key={index}
                  to={link.url}
                  className="group relative px-1 xl:mb-1 mb-5 mr-0 text-base font-bold lg:mb-0 sm:mr-4 lg:mr-8"
                >
                  {link.label}
                  {location.pathname === link.url ? (
                    <span className="absolute bottom-0 left-0 w-full h-1 -mb-2 bg-gradient-to-r from-red to-primary rounded-full"></span>
                  ) : (
                    <span className="absolute bottom-0 left-0 w-full h-1 -mb-2 group-hover:bg-primary-300 rounded-full"></span>
                  )}
                </Link>
              );
              if (link?.institutions) {
                if (!link.institutions.includes(institution)) {
                  linkItem = null;
                }
              }
              return linkItem;
            })}

            <LanguageControl isNavLink />

            <InstitutionControl />

            <div className="relative mb-5 sm:mb-0">
              <span className="hidden lg:block absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-black"></span>
              <Link
                to={isLoggedIn ? "/home" : "/login"}
                className="relative inline-block w-full h-full px-3 py-1 text-lg font-bold transition duration-100 bg-white border-2 border-black fold-bold hover:bg-primary-300 hover:text-gray-900"
              >
                {isLoggedIn ? "Dashboard" : "Login"}
              </Link>
            </div>
          </nav>
        </div>

        <div
          ref={menuBtnRef}
          onClick={toggleMenu}
          id="nav-mobile-btn"
          className="absolute top-0 right-0 z-50 block w-6 mt-8 mr-10 cursor-pointer select-none lg:hidden sm:mt-10"
        >
          <span className="block w-full h-1 mt-2 duration-200 transform bg-gray-800 rounded-full sm:mt-1"></span>
          <span className="block w-full h-1 mt-1 duration-200 transform bg-gray-800 rounded-full"></span>
        </div>
      </div>
    </div>
  );
};

export default BHead;
