import i18n from "i18next";
import HttpApi from "i18next-http-backend";

import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    // we init with resources
    // resources: {
    //   en: {
    //     translations: {
    //       Courses: "Courses",

    //       //   Declarative: "Declarative",

    //       //   "React makes it painless to create interactive UIs. Design simple views for each state in your application, and React will efficiently update and render just the right components when your data changes.":
    //       //     "React makes it painless to create interactive UIs. Design simple views for each state in your application, and React will efficiently update and render just the right components when your data changes.",

    //       //   "Declarative views make your code more predictable and easier to debug.":
    //       //     "Declarative views make your code more predictable and easier to debug.",
    //     },
    //   },

    //   es: {
    //     translations: {
    //       Courses: "Cursos",

    //       //   Declarative: "Δηλωτικό",

    //       //   "React makes it painless to create interactive UIs. Design simple views for each state in your application, and React will efficiently update and render just the right components when your data changes.":
    //       //     'To {{name}} καθιστά ανώφελη τη δημιουργία διαδραστικών διεπαφών χρήστη. Σχεδιάστε απλές προβολές για κάθε κράτος στο δικό σας\\n" +\n' +
    //       //     "                    εφαρμογή και το React θα ενημερώσει αποτελεσματικά και θα αποδώσει τα σωστά στοιχεία όταν τα δεδομένα σας " +
    //       //     "                    αλλαγές.",

    //       //   "Declarative views make your code more predictable and easier to debug.":
    //       //     "Οι δηλωτικές προβολές καθιστούν τον κώδικα πιο προβλέψιμο και πιο εύκολο στον εντοπισμό σφαλμάτων.",
    //     },
    //   },
    // },

    fallbackLng: "en",

    // debug: true,
    debug: process.env.NODE_ENV === "development",

    // have a common namespace used around the full app

    ns: ["translations"],

    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!

      formatSeparator: ",",
    },

    react: {
      wait: true,
    },
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
  });

export default i18n;
