import React, { useState, useLayoutEffect } from "react";
import { Navigate, useParams } from "react-router-dom";

import useSWR from "swr";
import toast from "react-hot-toast";

import { fetcher } from "utils/axios";

const SubtopicAccessHOC = ({ children }) => {
  const { courseSlug, subtopicSlug, slug } = useParams();
  // const [hasChecked, setHasChecked] = useState(false);
  const [hasAccess, setHasAccess] = useState(true);
  const { data: accessData, error: checkAccessErr } = useSWR(
    `/course-secondary/${courseSlug}/${
      subtopicSlug ?? slug
    }/check-subtopic-access/`,
    fetcher
  );

  // accessData && console.log(accessData);

  useLayoutEffect(() => {
    if (!checkAccessErr) return;
    if (checkAccessErr) {
      setHasAccess(false);
      toast.error("Unable to validate access!");
    }
    accessData && !accessData.has_access && setHasAccess(false);
  }, [accessData, checkAccessErr]);

  return <>{hasAccess ? children : <Navigate to="/home" />}</>;
};

export default SubtopicAccessHOC;
