import { lazy } from "react";

// use lazy for better code splitting, a.k.a. load faster
const ManageGroups = lazy(() => import("pages/groups/ManageGroups"));
const ManageSubgroups = lazy(() => import("pages/groups/ManageSubgroups"));
const GroupStaff = lazy(() => import("pages/groups/staff/GroupStaff"));

const groupRoutes = [
  {
    path: "/manage-groups",
    component: ManageGroups,
    title: "Manage Groups",
    permission: ["Admin"],
  },

  {
    path: "/:groupSlug/subgroups",
    component: ManageSubgroups,
    title: "Manage Subgroups",
    permission: ["Admin", "Group Manager"],
  },

  {
    path: "/:groupSlug/staff-area/",
    component: GroupStaff,
    title: "Manage Group Staff",
    permission: ["Admin", "Group Manager"],
  },
];

export default groupRoutes;

// export const publicRoutes = [
//   {
//     path: "/schools/:schoolSlug/preview",
//     component: SchoolPreview,
//     title: "School Preview",
//   },
// ];
