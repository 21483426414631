import { lazy } from "react";

import BatchAccessHOC from "routes/authGuards/BatchAccessHOC";
import GradeAccessHOC from "routes/authGuards/GradeAccessHOC";

const GradeCertificate = lazy(() =>
  import("pages/certificates/GradeCertificate")
);
const BatchCertificate = lazy(() =>
  import("pages/certificates/BatchCertificate")
);

const certificateRoutes = [
  {
    path: "/schools/:schoolSlug/:gradeSlug/certificate",
    component: GradeCertificate,
    title: "Grade Certificate",
    authGuard: GradeAccessHOC,
  },
  {
    path: "/courses/:courseSlug/batch-:batchId/certificate",
    component: BatchCertificate,
    title: "Batch Certificate",
    authGuard: BatchAccessHOC,
  },
];

export default certificateRoutes;
