import React from "react";

import { useTranslation } from "react-i18next";
// import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";

const Trans = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <>{i18n.exists(props.children) ? t(props.children) : props.children}</>
  );
};

export default Trans;
