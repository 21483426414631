import { lazy } from "react";

import userRoutes from "./appRoutes/userRoutes";
import schoolRoutes from "./appRoutes/schoolRoutes";
import gradeRoutes from "./appRoutes/gradeRoutes";
import subjectRoutes from "./appRoutes/subjectRoutes";
import chapterRoutes from "./appRoutes/chapterRoutes";
import taskRoutes from "./appRoutes/taskRoutes";
import staffRoutes from "./appRoutes/staffRoutes";
import blogRoutes from "./appRoutes/blogRoutes";
import spaceRoutes from "./appRoutes/spaceRoutes";
import examRoutes from "./appRoutes/examRoutes";
import certificateRoutes from "./appRoutes/certificateRoutes";
import marksheetRoutes from "./appRoutes/marksheetRoutes";
import clubRoutes from "./appRoutes/clubRoutes";
import ainaRoutes from "./appRoutes/ainaRoutes";
import groupRoutes from "./appRoutes/groupRoutes";
import coursesRoutes from "./appRoutes/courseRoutes";
import cmsRoutes from "./appRoutes/cmsRoutes";
import archiveRoutes from "./appRoutes/archiveRoutes";
import moreRoutes from "./appRoutes/moreRoutes";
import financeRoutes from "./appRoutes/financeRoutes";
import holisticRoutes from "./appRoutes/holisticRoutes";
import careerRoutes from "./appRoutes/careerRoutes";

// use lazy for better code splitting, a.k.a. load faster
const Home = lazy(() => import("pages/Home"));
const Dashboard = lazy(() => import("pages/platform/dashboard/Main"));
const Settings = lazy(() => import("pages/Settings"));
const ConnectZoom = lazy(() => import("pages/liveclass/ConnectZoom"));
const MyNotifications = lazy(() => import("pages/users/MyNotifications"));
const VerifyPayment = lazy(() => import("pages/VerifyPayment"));
const More = lazy(() => import("pages/More"));

const protectedRoutes = [
  {
    path: "/home", // the url
    component: Home, // view rendered
    title: "Home",
  },

  {
    path: "/dashboard", // the url
    component: Dashboard, // view rendered
    title: "Dashboard",
  },
  {
    path: "/settings", // the url
    component: Settings, // view rendered
    title: "Settings",
    // permission: ["Admin"],
  },
  {
    path: "/connect-with-zoom",
    component: ConnectZoom, // view rendered
    title: "Connect Zoom",
    permission: ["Admin"],
  },

  {
    path: "/my-notifications",
    component: MyNotifications,
    title: "My Notifications",
  },
  {
    path: "/verify-payment",
    component: VerifyPayment,
    title: "Verify Payment",
  },
  {
    path: "/more",
    component: More,
    title: "More",
  },

  ...userRoutes,
  ...schoolRoutes,
  ...gradeRoutes,
  ...subjectRoutes,
  ...chapterRoutes,
  ...taskRoutes,
  ...staffRoutes,
  ...blogRoutes,
  ...spaceRoutes,
  ...examRoutes,
  ...certificateRoutes,
  ...marksheetRoutes,
  ...clubRoutes,
  ...ainaRoutes,
  ...groupRoutes,
  ...coursesRoutes,
  ...cmsRoutes,
  ...archiveRoutes,
  ...moreRoutes,
  ...financeRoutes,
  ...holisticRoutes,
  ...careerRoutes,
];

export default protectedRoutes;
