import { lazy } from "react";

// import SchoolAdminHOC from "routes/authGuards/SchoolAdminHOC";
import SubjectAdminAccessHOC from "routes/authGuards/SubjectAdminAccessHOC";
import ChapterAccessHOC from "routes/authGuards/ChapterAccessHOC";

// use lazy for better code splitting, a.k.a. load faster
// const ManageChapters = lazy(() => import("pages/chapters/ManageChapters"));
const ManageChapterContents = lazy(() =>
  import("pages/chapters/ManageChapterContents")
);
const ChapterContents = lazy(() =>
  import("pages/schools/student/ChapterContents")
);

const chapterRoutes = [
  // {
  //   path: "schools/:schoolSlug/:courseSlug/:slug/contents", // the url
  //   component: ManageChapters, // view rendered
  //   title: "Manage Chapters",
  // },
  {
    path: "schools/:schoolSlug/:gradeSlug/:subjectSlug/:slug/contents", // the url
    component: ManageChapterContents, // view rendered
    title: "Chapter Contents",
    // permission: ["Admin", "Group Manager", "SubGroup Manager"],
    authGuard: SubjectAdminAccessHOC,
  },
  {
    path: ":schoolSlug/:gradeSlug/:subjectSlug/:slug/contents", // the url
    component: ChapterContents, // view rendered
    title: "Chapter Contents",
    authGuard: ChapterAccessHOC,
  },
];

export default chapterRoutes;
