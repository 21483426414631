import { lazy } from "react";

import GradeAccessHOC from "routes/authGuards/GradeAccessHOC";

// use lazy for better code splitting, a.k.a. load faster
const GeneralAttendance = lazy(() =>
  import("pages/reporting/GeneralAttendance")
);
const MealAttendance = lazy(() => import("pages/reporting/LunchAttendance"));
const VehicleAttendance = lazy(() => import("pages/reporting/BusAttendance"));

const attendanceRoutes = [
  {
    path: "/:schoolSlug/:gradeSlug/attendance/general",
    component: GeneralAttendance,
    title: "General Attendance",
    // permission: ["Teacher", "Supervisor"],
    authGuard: GradeAccessHOC,
  },
  {
    path: "/:schoolSlug/:gradeSlug/attendance/lunch",
    component: MealAttendance,
    title: "Lunch Attendance",
    // permission: ["Teacher", "Supervisor"],
    authGuard: GradeAccessHOC,
  },

  {
    path: "/schools/:schoolSlug/vehicle-:vehicleID/reporting/",
    component: VehicleAttendance,
    title: "Vehicle Reporting",
    // authGuard: SchoolAdminHOC,
  },
];

export default attendanceRoutes;
