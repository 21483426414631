import { lazy } from "react";

import SubgroupAdminHOC from "routes/authGuards/SubgroupAdminHOC";

// use lazy for better code splitting, a.k.a. load faster
// const HolisticDashboard = lazy(() =>
//   import("pages/institution/holistic/HolisticDashboard")
// );
const HolisticCourses = lazy(() =>
  import("pages/institution/holistic/HolisticCourses")
);
const ManageCurriculum = lazy(() =>
  import("pages/institution/holistic/curriculum/ManageCurriculum")
);
const ManageCurriculumCourses = lazy(() =>
  import("pages/institution/holistic/curriculum/ManageCurriculumCourses")
);
const ManageCourseConnections = lazy(() =>
  import("pages/institution/holistic/curriculum/ManageCourseConnections")
);

const holisticRoutes = [
  // {
  //   path: "/holistic/dashboard",
  //   component: HolisticDashboard,
  //   title: "Holistic Education Dashboard",
  //   permission: ["Admin"],
  // },
  {
    path: "/:slug/courses",
    component: HolisticCourses,
    title: "Holistic Courses",
    // permission: ["Admin"],
    authGuard: SubgroupAdminHOC,
  },
  {
    path: "/:slug/manage-curriculum",
    component: ManageCurriculum,
    title: "Manage Curriculum",
    authGuard: SubgroupAdminHOC,
  },
  {
    path: "/:subgroupSlug/:slug/manage-courses",
    component: ManageCurriculumCourses,
    title: "Manage Curriculum Courses",
    authGuard: SubgroupAdminHOC,
  },
  {
    path: "/:subgroupSlug/:curSlug/:slug/manage-connections",
    component: ManageCourseConnections,
    title: "Manage Course Connections",
    authGuard: SubgroupAdminHOC,
  },
];

export default holisticRoutes;

// export const publicRoutes = [
//   {
//     path: "/schools/:schoolSlug/preview",
//     component: SchoolPreview,
//     title: "School Preview",
//   },
// ];
