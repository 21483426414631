import { lazy } from "react";

import CourseAdminHOC from "routes/authGuards/CourseAdminHOC";
import CourseAccessHOC from "routes/authGuards/CourseAccessHOC";
import TopicAccessHOC from "routes/authGuards/TopicAccessHOC";
import SubtopicAccessHOC from "routes/authGuards/SubtopicAccessHOC";

// use lazy for better code splitting, a.k.a. load faster
const CoursesPage = lazy(() => import("pages/courses/Courses"));
const ManageCourseStructure = lazy(() =>
  import("pages/courses/ManageCourseStructure")
);
const CourseEnrollments = lazy(() => import("pages/courses/CourseEnrollments"));
const CourseLiveClasses = lazy(() => import("pages/courses/CourseLiveClasses"));
const CourseMisc = lazy(() => import("pages/courses/CourseMisc"));
const CourseTeam = lazy(() => import("pages/courses/CourseTeam"));
const CourseContents = lazy(() => import("pages/courses/CourseContents"));
const TopicContents = lazy(() => import("pages/courses/TopicContents"));
const SubtopicContents = lazy(() => import("pages/courses/SubtopicContents"));
const OurCourses = lazy(() => import("pages/courses/OurCourses"));
const CoursePreview = lazy(() => import("pages/courses/CoursePreview"));
const AddCourse = lazy(() => import("pages/courses/AddCourse"));
const UpdateCourse = lazy(() => import("pages/courses/UpdateCourse"));
const ManageBatchEnrollments = lazy(() =>
  import("components/courses/batches/ManageBatchEnrollments")
);

const coursesRoutes = [
  {
    path: "/courses",
    component: CoursesPage,
    title: "Courses",
  },
  {
    path: "/courses/add-course",
    component: AddCourse,
    title: "New Course",
    permission: ["Admin", "Group Manager", "SubGroup Manager", "Supervisor"],
  },
  {
    path: "/courses/:courseSlug/update",
    component: UpdateCourse,
    title: "Update Course",
    authGuard: CourseAdminHOC,
  },
  {
    path: "/courses/:courseSlug/manage-structure/",
    component: ManageCourseStructure,
    title: "Manage Course Structure",
    authGuard: CourseAdminHOC,
  },
  {
    path: "/courses/:courseSlug/enrollments/",
    component: CourseEnrollments,
    title: "Course Enrollments",
    authGuard: CourseAdminHOC,
  },
  {
    path: "/courses/:courseSlug/live-classes/",
    component: CourseLiveClasses,
    title: "Course Live Classes",
    authGuard: CourseAdminHOC,
  },
  {
    path: "/courses/:courseSlug/team/",
    component: CourseTeam,
    title: "Course Team",
    authGuard: CourseAdminHOC,
  },
  {
    path: "/courses/:courseSlug/miscellaneous/",
    component: CourseMisc,
    title: "Course Miscellaneous",
    authGuard: CourseAdminHOC,
  },

  {
    path: "/courses/:courseSlug/batch-:batchID/enrollments",
    component: ManageBatchEnrollments,
    title: "Manage Batch Enrollments",
    authGuard: CourseAdminHOC,
  },

  {
    path: "/courses/:courseSlug/contents/",
    component: CourseContents,
    title: "Course Contents",
    authGuard: CourseAccessHOC,
  },

  {
    path: "/courses/:courseSlug/:topicSlug/contents/",
    component: TopicContents,
    title: "Topic Contents",
    authGuard: TopicAccessHOC,
  },

  {
    path: "/courses/:courseSlug/:topicSlug/:subtopicSlug/contents/",
    component: SubtopicContents,
    title: "Subtopic Contents",
    authGuard: SubtopicAccessHOC,
  },
];

export default coursesRoutes;

export const publicRoutes = [
  {
    path: "/our-courses",
    component: OurCourses,
    title: "Our Courses",
  },
  {
    path: "/courses/:courseSlug/preview",
    component: CoursePreview,
    title: "Course Preview",
  },
];
