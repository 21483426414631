import React from "react";
// import { Route } from "react-router-dom";

import Head from "layouts/blocks/BHead";
import Footer from "layouts/blocks/BFooter";
import useDocTitle from "utils/useDocTitle";

const Base = ({ component: Component, docTitle }) => {
  let title = "UED";
  if (docTitle) {
    title = `UED | ${docTitle}`;
  }
  useDocTitle(title);

  return (
    <main className="overflow-x-hidden antialiased">
      <Head />
      <Component />
      <Footer />
    </main>
  );
};

export default Base;

// const BaseRoute = ({ component: Component, docTitle, ...rest }) => {
//   // let title = "KOX - Keep on";
//   // if (docTitle) {
//   //   title = `KOX | ${docTitle}`;
//   // }
//   // useDocTitle(title);

//   return (
//     <Route
//       {...rest}
//       render={(props) => (
//         <Base>
//           <Component {...props} />
//         </Base>
//       )}
//     />
//   );
// };

// export default BaseRoute;
