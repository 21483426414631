import { lazy } from "react";

// import SchoolAdminHOC from "routes/authGuards/SchoolAdminHOC";
import GradeAdminAccessHOC from "routes/authGuards/GradeAdminAccessHOC";
import SubjectAdminAccessHOC from "routes/authGuards/SubjectAdminAccessHOC";
import SubjectAccessHOC from "routes/authGuards/SubjectAccessHOC";

// use lazy for better code splitting, a.k.a. load faster
const ManageSubjects = lazy(() => import("pages/subjects/ManageSubjects"));
const ManageSubjectContents = lazy(() =>
  import("pages/subjects/ManageSubjectContents")
);
const SubjectContents = lazy(() =>
  import("pages/schools/student/SubjectContents")
);

const subjectRoutes = [
  {
    path: "/schools/:schoolSlug/:slug/subjects", // the url
    component: ManageSubjects, // view rendered
    title: "Manage Subjects",
    // permission: ["Admin", "Group Manager", "SubGroup Manager"],
    authGuard: GradeAdminAccessHOC,
  },
  {
    path: "schools/:schoolSlug/:gradeSlug/:slug/contents", // the url
    component: ManageSubjectContents, // view rendered
    title: "Manage Subject Contents",
    // permission: ["Admin", "Group Manager", "SubGroup Manager"],
    authGuard: SubjectAdminAccessHOC,
  },
  {
    path: ":schoolSlug/:gradeSlug/:slug/contents", // the url
    component: SubjectContents, // view rendered
    title: "Subject Contents",
    authGuard: SubjectAccessHOC,
  },
];

export default subjectRoutes;
