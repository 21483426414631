import { lazy } from "react";

import SchoolsManagerHOC from "routes/authGuards/SchoolsManagerHOC";
import SchoolAdminHOC from "routes/authGuards/SchoolAdminHOC";
import SchoolAccessHOC from "routes/authGuards/SchoolAccessHOC";
import attendanceRoutes from "./attendanceRoutes";
import calendarRoutes from "./calendarRoutes";
import libraryRoutes from "./libraryRoutes";

// use lazy for better code splitting, a.k.a. load faster
const ManageSchools = lazy(() => import("pages/schools/ManageSchools"));
const SchoolPreview = lazy(() => import("pages/schools/SchoolPreview"));
// const SchoolDetail = lazy(() => import("pages/schools/SchoolDetail"));
const UpdateSchool = lazy(() => import("pages/schools/UpdateSchool"));
const OurSchools = lazy(() => import("pages/schools/OurSchools"));
const StudentDetails = lazy(() =>
  import("pages/schools/student/StudentDetails")
);
const SchoolEnrollments = lazy(() =>
  import("pages/schools/enrollments/SchoolEnrollments")
);
const SchoolSpaces = lazy(() => import("pages/schools/SchoolSpaces"));
// const SchoolPremises = lazy(() =>
//   import("pages/schools/premises/ManagePremises")
// );
const SchoolTransportation = lazy(() =>
  import("pages/schools/transportation/ManageTransport")
);
const ManageVehicleAccess = lazy(() =>
  import("pages/schools/transportation/access/VehicleAccess")
);
const SchoolMisc = lazy(() => import("pages/schools/SchoolMisc"));
const SchoolDashboard = lazy(() => import("pages/schools/SchoolDashboard"));
const ParentInvitations = lazy(() =>
  import("pages/schools/parents/ParentInvitations")
);

const schoolRoutes = [
  {
    path: "/schools/dashboard",
    component: SchoolDashboard,
    title: "Schools Dashboard",
  },
  {
    path: "/manage-schools",
    component: ManageSchools,
    title: "Manage School",
    permission: ["Admin", "Group Manager"],
    authGuard: SchoolsManagerHOC,
  },
  {
    path: "/schools/:slug/update",
    component: UpdateSchool,
    title: "Update School",
    permission: ["Admin", "Group Manager", "SubGroup Manager"],
    authGuard: SchoolAdminHOC,
  },
  {
    path: "/schools/student-details",
    component: StudentDetails,
    title: "Student Details",
    permission: ["Student"],
  },
  {
    path: "/schools/student-:studentID/details",
    component: StudentDetails,
    title: "Student Details",
    permission: ["Parent"],
  },
  {
    path: "/schools/student/my-parents",
    component: ParentInvitations,
    title: "Student Parents",
    permission: ["Student"],
  },

  {
    path: "/parents/my-invitations",
    component: ParentInvitations,
    title: "Parent Invitations",
    permission: ["Parent"],
  },

  {
    path: "/schools/:slug/enrollments",
    component: SchoolEnrollments,
    title: "School Enrollments",
    permission: ["Admin", "Group Manager", "SubGroup Manager"],
    authGuard: SchoolAdminHOC,
  },
  {
    path: "/schools/:slug/miscellaneous",
    component: SchoolMisc,
    title: "School Miscellaneous",
    authGuard: SchoolAdminHOC,
  },
  {
    path: "/schools/:slug/spaces",
    component: SchoolSpaces,
    title: "School Spaces",
    authGuard: SchoolAccessHOC,
  },

  // {
  //   path: "/schools/:slug/premises",
  //   component: SchoolPremises,
  //   title: "School Premises",
  //   authGuard: SchoolAccessHOC,
  // },

  {
    path: "/schools/:slug/transportation",
    component: SchoolTransportation,
    title: "School Transportation",
    permission: ["Admin", "Group Manager", "SubGroup Manager"],
    authGuard: SchoolAccessHOC,
  },
  {
    path: "/schools/:schoolSlug/vehicle-:vehicleID/manage-access/",
    component: ManageVehicleAccess,
    title: "Manage Vehicle Access",
    authGuard: SchoolAdminHOC,
  },

  ...attendanceRoutes,
  ...calendarRoutes,
  ...libraryRoutes,
];

export default schoolRoutes;

export const publicRoutes = [
  {
    path: "/schools/:schoolSlug/preview",
    component: SchoolPreview,
    title: "School Preview",
  },
  {
    path: "/our-schools",
    component: OurSchools,
    title: "Our Schools",
  },
];
