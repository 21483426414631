import { lazy } from "react";

// import SchoolAdminHOC from "routes/authGuards/SchoolAdminHOC";
import SchoolAccessHOC from "routes/authGuards/SchoolAccessHOC";

const ManageSites = lazy(() => import("pages/sites/ManageSites"));
const ManageSiteSpaces = lazy(() => import("pages/sites/ManageSiteSpaces"));
const SiteSpaceDetails = lazy(() => import("pages/sites/SiteSpaceDetails"));
const SchoolPremises = lazy(() =>
  import("pages/schools/premises/ManagePremises")
);
const LocationDetails = lazy(() =>
  import("pages/schools/premises/LocationDetails")
);

const spaceRoutes = [
  {
    path: "/partner-sites",
    component: ManageSites,
    title: "Partner Sites",
    permission: ["Admin"],
  },
  {
    path: "/site-:siteID/spaces",
    component: ManageSiteSpaces,
    title: "Manage Site Spaces",
    permission: ["Admin"],
  },

  {
    path: "/sites/site-:siteID/space-:spaceID/details", // the url
    component: SiteSpaceDetails, // view rendered
    title: "Site Space Details",
    // authGuard: SchoolAccessHOC,
  },

  {
    path: "/schools/:slug/premises", // the url
    component: SchoolPremises, // view rendered
    title: "School Premises",
    authGuard: SchoolAccessHOC,
  },

  {
    path: "/schools/:schoolSlug/premises/location-:locationID/details", // the url
    component: LocationDetails, // view rendered
    title: "Location Details",
    authGuard: SchoolAccessHOC,
  },
];

export default spaceRoutes;
