import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

const initialState = { id: null };

const institutionSlice = createSlice({
  name: "institution",
  initialState: initialState,
  reducers: {
    setInstitution(state, action) {
      state.id = action.payload.id;
    },
    resetLevel(state) {
      state.id = null;
    },
  },
});

export default institutionSlice;

export const useInstitution = () => {
  const institution = useSelector((state) => state.institution.id);
  return institution;
};
