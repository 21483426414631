import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

import useSWR from "swr";
import { Menu, Divider, Avatar } from "@mantine/core";
import { Group, Indicator } from "@mantine/core";
import { HiUser, HiBell } from "react-icons/hi";
import { MdAccountCircle, MdLogout } from "react-icons/md";
import { FcCheckmark } from "react-icons/fc";

import LanguageControl from "./LanguageControl";
import authSlice, { useCurrentUserRole } from "store/slices/auth";
import { useInstitution } from "store/slices/institution";
import { withStudentRole } from "utils/withRole";
import { fetcher } from "utils/axios";

const StudentMenuItem = withStudentRole(Menu.Item);

const UHead = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.account);
  const currentUserRole = useCurrentUserRole();
  const institution = useInstitution();
  const {
    data: navData,
    mutate,
    error,
  } = useSWR("/utility/top-navigation/", fetcher);

  error && console.log(error);

  useEffect(() => {
    mutate();
  }, [institution, mutate]);

  const handleLogout = () => {
    dispatch(authSlice.actions.logout());
    navigate("/login", { replace: true });
  };

  const handleRoleChange = (role) => {
    if (role.title === currentUserRole.title) return;
    dispatch(authSlice.actions.setRole(role));
    // window.location.reload();
    navigate("/home");
  };

  return (
    <div
      id="header"
      className="sticky z-10 top-0 h-16 border-b bg-white py-2.5"
    >
      <div className="px-6 flex items-center justify-end space-x-4 2xl:container">
        {/* <h5
          hidden
          className="text-2xl text-gray-600 font-medium lg:block"
        >
          Dashboard
        </h5> */}
        <div className="flex space-x-4">
          {/* <!--search bar --> */}
          {/* <div hidden className="md:block">
            <div className="relative flex items-center text-gray-400 focus-within:text-red-400">
              <span className="absolute left-4 h-6 flex items-center pr-3 border-r border-gray-300">
                <Search theme="outline" size="20" />
              </span>
              <input
                type="search"
                name="leadingIcon"
                id="leadingIcon"
                placeholder="Search here"
                className="w-full pl-14 pr-4 py-2.5 rounded-xl text-sm text-gray-600 outline-none border border-gray-300 focus:border-primary-300 transition"
              />
            </div>
          </div> */}
          {/* <!--/search bar --> */}
          {/* <Button className="bg-gradient-to-t hover:bg-gradient-to-b border-white from-red to-primary md:hidden shadow-inner px-2">
            <MenuFoldOne size="24" />
          </Button> */}

          <LanguageControl />

          {/* <SchoolView> */}
          <Link to="/my-notifications">
            <Indicator
              inline
              label={navData?.notifications_count}
              color="yellow"
              size={16}
            >
              <button
                aria-label="notification"
                className="w-10 h-10 rounded-xl border bg-gray-100 focus:bg-gray-100 active:bg-gray-200"
              >
                <HiBell className="h-5 w-5 m-auto text-gray-600" />
              </button>
            </Indicator>
          </Link>
          {/* </SchoolView> */}

          <Menu width={250}>
            <Menu.Target>
              <Avatar
                radius="md"
                classNames={{
                  root: "border-2 border-double border-primary shadow-lg",
                }}
                src={currentUser?.profile_image}
                alt={currentUser?.email ?? "Current User Profile"}
              />
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Label>{currentUserRole.title}</Menu.Label>
              {currentUser.roles?.length > 1 && (
                <>
                  <Group position="center" my="xs" px="xs">
                    {currentUser.roles.map((role, index) => (
                      <div key={index}>
                        <button
                          key={role.title}
                          onClick={() => handleRoleChange(role)}
                          className="flex items-center border-2 border-black rounded-md border-b-4 border-l-4 font-bold px-2 gap-x-2"
                        >
                          {currentUserRole.title === role.title && (
                            <FcCheckmark size={20} />
                          )}
                          <span>{role.title}</span>
                        </button>
                      </div>
                    ))}
                  </Group>

                  <Divider />
                </>
              )}
              <StudentMenuItem
                to="/schools/student-details"
                component={Link}
                className="text-lg hover:bg-gray-50"
                icon={<HiUser fontSize={24} />}
              >
                My Profile
              </StudentMenuItem>
              <Menu.Item
                to="/users/my-account"
                component={Link}
                className="text-lg hover:bg-gray-50"
                icon={<MdAccountCircle fontSize={24} />}
              >
                My Account
              </Menu.Item>

              <Divider />

              <Menu.Label>{currentUser?.email}</Menu.Label>
              <Menu.Item
                onClick={handleLogout}
                className="text-base hover:bg-gray-50"
                icon={<MdLogout fontSize={24} />}
              >
                Logout
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default UHead;
