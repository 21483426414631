import React from "react";

import useSWR from "swr";
import { useTranslation } from "react-i18next";
import { Group, Menu } from "@mantine/core";
import { MdOutlineLanguage } from "react-icons/md";

import LoadingDots from "components/misc/LoadingDots";
import axiosService, { fetcher } from "utils/axios";

const LanguageControl = ({ isNavLink = false }) => {
  const { i18n } = useTranslation();
  const {
    data: lang,
    mutate,
    error: getLangErr,
  } = useSWR("/utility/get-language/", fetcher);

  getLangErr && console.log(getLangErr);
  // lang && console.log(lang);

  const changeLanguage = (code) => {
    if (lang && code === lang.current) return;
    let url = "/utility/set-language/";
    let data = {
      language: code,
    };

    axiosService
      .post(url, data)
      .then(() => {
        mutate();
        i18n.changeLanguage(code);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Menu>
      <Menu.Target>
        <Group
          className={
            isNavLink
              ? "border-black border-2 py-1.5 px-3 lg:shadow-[3px_3px_0_0_#000] hover:bg-primary-300 mr-3 mb-3 sm:mb-0"
              : "rounded-xl border bg-gray-100 focus:bg-gray-100 active:bg-gray-200 pl-3 pr-2"
          }
        >
          <span className="font-bold">{lang && lang.current}</span>
          <button
            className={
              isNavLink
                ? "group relative text-base font-bold block sm:hidden lg:block -ml-2"
                : "w-6 h-10"
            }
          >
            <MdOutlineLanguage className="h-5 w-5 m-auto text-gray-600" />
          </button>
        </Group>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>Preferred Language</Menu.Label>

        <div className="py-2">
          {lang ? (
            lang.options.length > 0 ? (
              lang.options.map((language) => (
                <Menu.Item
                  key={language[0]}
                  onClick={() => changeLanguage(language[0])}
                  className={`text-base ${
                    language[0] === lang.current
                      ? "bg-gradient-vertical text-white"
                      : "hover:bg-gray-50"
                  }`}
                >
                  {language[1]} ({language[0]})
                </Menu.Item>
              ))
            ) : (
              <p>No languages found!</p>
            )
          ) : (
            <LoadingDots visible />
          )}
        </div>
      </Menu.Dropdown>
    </Menu>
  );
};

export default LanguageControl;
