import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import useSWR from "swr";
import toast from "react-hot-toast";
import { Group, Image } from "@mantine/core";

import NotAvailable from "components/misc/NotAvailable";
import LoadingDots from "components/misc/LoadingDots";
import axiosService, { fetcher } from "utils/axios";
import institutionSlice from "store/slices/institution";
import { useInstitution } from "store/slices/institution";

export const institutePaths = {
  aina: "/aina-institute",
  academy: "/ued-academy",
  school: "/ued-school",
  university: "/ued-university",
  holistic: "/holistic-education",
  all: "/ued-for-all",
};

const PlatformSelect = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const institution = useInstitution();
  const { data: groups, error: getGroupsErr } = useSWR(
    "/course-group/get-course-groups/?platform=true",
    fetcher
  );

  getGroupsErr && toast.error("Error while loading groups!");
  // groups && console.log(groups);

  const selectInstitution = (id) => {
    let url = "/utility/set-institution/";
    let data = {
      institution: id,
    };
    axiosService
      .post(url, data)
      .then(() => {
        dispatch(institutionSlice.actions.setInstitution({ id }));
        institution !== id && toast.success("Institution Selected!");
        navigate(institutePaths[id]);
      })
      .catch(() => {
        toast.error("Unable to set institution!");
      });
  };

  return (
    <>
      <Group px="lg" mt="sm" className="justify-center sm:justify-start">
        <p className="text-2xl sm:text-3xl font-extrabold bg-gradient-to-b from-primary to-red text-transparent bg-clip-text">
          Our Institutions
        </p>
      </Group>
      {groups ? (
        groups.length > 0 ? (
          <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 py-5 px-3">
            {groups.map((group) => (
              <div
                key={group.id}
                onClick={() => selectInstitution(group.group_type)}
                className="group flex flex-col items-center justify-center hover:border-red border-2 border-white transition-all transform duration-400 cursor-pointer p-2"
              >
                <Image
                  height={100}
                  width={100}
                  fit="contain"
                  src={group.logo}
                  alt={group.title}
                />
                <p className="text-center text-lg sm:text-xl font-bold mt-3 group-hover:text-red">
                  {group.title}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <NotAvailable label="No institution found!" />
        )
      ) : (
        <LoadingDots visible />
      )}
    </>
  );
};

export default PlatformSelect;
