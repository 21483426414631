import React, { useEffect, useState } from "react";

import useSWR from "swr";
import toast from "react-hot-toast";
import tw from "tailwind-styled-components";
import * as Yup from "yup";
import { Group, Button, Stack } from "@mantine/core";
import { useFormik } from "formik";

import LoadingDots from "components/misc/LoadingDots";
import axiosService, { fetcher } from "utils/axios";
import { SelectSingle, TextInput } from "components/misc/Inputs";
import { dictToFormData, addUserMeta } from "utils/helpers";

export const ErrorMsg = tw.p`font-semibold text-red-500`;

const NewSupervisor = ({
  schoolSlug,
  institution,
  mutate,
  close,
  isUpdate = false,
  updateApiURL = null,
}) => {
  const [gradeData, setGradeData] = useState(null);
  const [supervisorData, setSupervisorData] = useState(null);
  const [supervisor, setSupervisor] = useState(null);
  const [loading, setLoading] = useState(true);
  const { data: initialData, error: getInitialErr } = useSWR(
    `/schools/managers/${schoolSlug}/course-team-initial/`,
    fetcher
  );

  getInitialErr && toast.error("Error while loading initial data!");
  //   initialData && console.log(initialData);

  useEffect(() => {
    if (isUpdate && updateApiURL) {
      axiosService
        .get(updateApiURL)
        .then((res) => {
          // console.log(res);
          setSupervisor(res.data);
        })
        .catch(() => {
          toast.error("Error while loading supervisor!");
        });
    }
  }, [isUpdate, updateApiURL]);

  useEffect(() => {
    if (!initialData) return;
    // console.log("rendered");
    let grades = [];
    initialData.grades.forEach((item) => {
      // console.log(option)
      grades.push({
        label: item.title,
        value: item.id.toString(),
      });
    });
    // console.log(activeOptions);
    setGradeData(grades);
    setLoading(false);
  }, [initialData]);

  const addSchema = Yup.object().shape({
    course_object: Yup.string().required("Required"),
    user: Yup.string().required("Required"),
    designation: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      course_object: supervisor ? supervisor.course_object.toString() : "",
      user: supervisor ? supervisor.user.toString() : "",
      designation: supervisor?.designation ? supervisor.designation : "",
    },
    enableReinitialize: true,
    onSubmit: (values, resetForm) => {
      if (isUpdate) {
        performUpdate(values);
      } else {
        performAdd(values, resetForm);
      }
    },
    validationSchema: addSchema,
  });

  useEffect(() => {
    if (formik.values.course_object === "") return;
    setLoading(true);
    axiosService
      .get(
        `/schools/managers/${schoolSlug}/${formik.values.course_object}/unassigned-supervisors/`
      )
      .then((res) => {
        // console.log(res);
        let supervisors = [];
        if (res.data.length > 0) {
          res.data.forEach((item) => {
            // console.log(option)
            supervisors.push({
              label: `${item.email} - ${item.username}`,
              value: item.id.toString(),
            });
          });
        }
        // console.log(activeOptions);
        setSupervisorData(supervisors);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        toast.error("Error while loading supervisors!");
      });
  }, [formik.values.course_object, schoolSlug]);

  const performAdd = (values, { resetForm }) => {
    setLoading(true);
    let data_dict = { ...values };
    // data_dict.thumbnail === null && delete data_dict["thumbnail"];

    let data = dictToFormData(data_dict);
    // data_dict.thumbnail && data.append("thumbnail", data_dict.thumbnail);
    data = addUserMeta(data, "create");
    data.append("member_type", "coordinator");
    //viewFormData(data);
    axiosService
      .post(`/schools/managers/${schoolSlug}/grade-staff/`, data)
      .then(() => {
        // console.log(res);
        mutate();
        toast.success(`Invitation Sent!`);
        close();
        setLoading(false);
        resetForm();
      })
      .catch(() => {
        setLoading(false);
        toast.error(`Could not invite user!`);
      });
  };

  const performUpdate = (values) => {
    if (!updateApiURL) return;
    setLoading(true);
    let data = {
      course_object: values.course_object,
      designation: values.designation,
    };

    axiosService
      .patch(updateApiURL, data)
      .then(() => {
        mutate();
        toast.success("Supervisor Updated!");
        setLoading(true);
        close();
      })
      .catch(() => {
        setLoading(true);
        toast.error("Error while updating!");
      });
  };

  return (
    <form onSubmit={formik.handleSubmit} className="-mt-3 relative">
      {(loading || (isUpdate && supervisor === null)) && (
        <LoadingDots visible />
      )}
      <Stack>
        {gradeData && (
          <SelectSingle
            searchable
            label={`Select ${institution === "school" ? "Grade" : "Course"}`}
            placeholder="Please search using name"
            data={gradeData}
            value={formik.values.course_object}
            onChange={(value) => formik.setFieldValue("course_object", value)}
            required
          />
        )}
        {formik.touched.course_object && formik.errors.course_object && (
          <ErrorMsg>{formik.errors.course_object}</ErrorMsg>
        )}
      </Stack>

      <Stack>
        {!isUpdate && supervisorData && (
          <SelectSingle
            searchable
            label="Select Supervisor"
            placeholder="Search using email or username"
            data={supervisorData}
            value={formik.values.user}
            onChange={(value) => formik.setFieldValue("user", value)}
            required
          />
        )}
        {formik.touched.user && formik.errors.user && (
          <ErrorMsg>{formik.errors.user}</ErrorMsg>
        )}
      </Stack>

      <Stack>
        <TextInput
          name="designation"
          label="Designation"
          placeholder="Supervisor / Coordinator"
          value={formik.values.designation}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.designation && formik.errors.designation && (
          <ErrorMsg>{formik.errors.designation}</ErrorMsg>
        )}
      </Stack>

      <Group className="justify-between" mt="sm">
        <Button
          disabled={
            isUpdate && supervisor
              ? supervisor.course_object.toString() ===
                  formik.values.course_object &&
                supervisor.designation === formik.values.designation
              : false
          }
          type="submit"
          variant="light"
          color={isUpdate ? "yellow" : "teal"}
          className={isUpdate ? "bg-yellow-100" : "bg-teal-100"}
        >
          {isUpdate ? "Save Changes" : "Confirm"}
        </Button>
        <Button onClick={close} variant="subtle" color="red">
          Cancel
        </Button>
      </Group>
    </form>
  );
};

export default NewSupervisor;
