import { lazy } from "react";

import { publicRoutes as schoolRoutes } from "./appRoutes/schoolRoutes";
import { publicRoutes as blogRoutes } from "./appRoutes/blogRoutes";
import { publicRoutes as courseRoutes } from "./appRoutes/courseRoutes";
import { publicRoutes as libraryRoutes } from "./appRoutes/libraryRoutes";

// use lazy for better code splitting, a.k.a. load faster
// const Platform = lazy(() => import("pages/platform/Platform"));
const AinaInstitute = lazy(() => import("pages/ainainstitute/AinaInstitute"));
const UedAcademy = lazy(() => import("pages/institution/UedAcademy"));
const UedForAll = lazy(() => import("pages/institution/UedForAll"));
const UedSchool = lazy(() => import("pages/schools/UedSchool"));
const UedUniversity = lazy(() => import("pages/institution/UedUniversity"));
const Holistic = lazy(() => import("pages/institution/HolisticEducation"));
const Index = lazy(() => import("pages/platform/Index"));
const Contact = lazy(() => import("pages/platform/Contact"));

const publicRoutes = [
  {
    path: "/", // the url
    component: Index, // view rendered
    title: "",
  },

  {
    path: "/aina-institute",
    component: AinaInstitute,
    title: "Aina Institute",
  },

  {
    path: "/ued-academy",
    component: UedAcademy,
    title: "Academy",
  },

  {
    path: "/ued-for-all",
    component: UedForAll,
    title: "For All",
  },

  {
    path: "/ued-school",
    component: UedSchool,
    title: "School",
  },

  {
    path: "/ued-university",
    component: UedUniversity,
    title: "University",
  },

  {
    path: "/holistic-education",
    component: Holistic,
    title: "Holistic Education",
  },

  {
    path: "/contact",
    component: Contact,
    title: "UED - Contact Us",
  },

  ...schoolRoutes,
  ...blogRoutes,
  ...courseRoutes,
  ...libraryRoutes,
];

export default publicRoutes;
