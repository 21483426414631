import { lazy } from "react";

import SchoolAdminHOC from "routes/authGuards/SchoolAdminHOC";

// use lazy for better code splitting, a.k.a. load faster
const OurBlogs = lazy(() => import("pages/blogs/OurBlogs"));
const ManageBlogs = lazy(() => import("pages/blogs/ManageBlogs"));
const ManageSchoolBlogs = lazy(() => import("pages/blogs/ManageSchoolBlogs"));
const ReadBlog = lazy(() => import("pages/blogs/ReadBlog"));

const blogRoutes = [
  {
    path: "manage-blogs",
    component: ManageBlogs,
    title: "Manage Blogs",
    permission: ["Admin", "Group Manager", "SubGroup Manager"],
  },
  {
    path: "schools/:schoolSlug/manage-blogs",
    component: ManageSchoolBlogs,
    title: "Manage School Blogs",
    // permission: ["Admin", "Group Manager", "SubGroup Manager"],
    authGuard: SchoolAdminHOC,
  },
];

export const publicRoutes = [
  {
    path: "blogs",
    component: OurBlogs,
    title: "Our Blogs",
  },
  {
    path: "blogs/:slug/read",
    component: ReadBlog,
    title: "Read Blog",
  },
];

export default blogRoutes;
