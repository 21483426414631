import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import { useSelector } from "react-redux";

import OtpInput from "react-otp-input";
import toast from "react-hot-toast";
import axiosService from "utils/axios";
import { Group, Button } from "@mantine/core";
import { CheckSmall } from "@icon-park/react";

const VerifyAccount = ({ email, reset = null }) => {
  const navigate = useNavigate();
  const location = useLocation();
  //   const currentUserID = useSelector((state) => state.auth.account.id);
  const [code, setCode] = useState("");

  const handleCodeVerification = (e, type = "validate") => {
    e.preventDefault();
    let data = {
      email,
      resend_verification_code: type === "validate" ? false : true,
      code,
    };

    // console.log(data, vcode);
    axiosService
      .post(`/accounts/verify-account/`, data)
      .then((res) => {
        // console.log(res);
        toast.success(res.data.message);
        if (type === "validate") {
          location.pathname === "/login"
            ? reset && reset()
            : navigate("/login");
        }
      })
      .catch((err) => {
        let msg = "Could not verify email!";
        if (err.response.data.message) {
          msg = err.response.data.message;
        }
        toast.error(msg);
      });
  };

  return (
    <>
      <div className="container mx-auto my-5">
        <div className="max-w-sm mx-auto md:max-w-lg">
          <div className="w-full">
            <div className="bg-white/50 shadow-inner h-64 py-3 rounded text-center">
              <h1 className="text-2xl font-bold text-gray-600">
                Email Verification
              </h1>
              <div className="flex flex-col mt-4 text-lg">
                <span>Please enter the 6 digit code you received at</span>
                <span className="font-bold">{email}</span>
              </div>

              <Group position="center" mt="sm">
                <OtpInput
                  isInputNum
                  value={code}
                  onChange={(otp) => setCode(otp)}
                  numInputs={6}
                  separator={<span className="mx-1 font-thin">-</span>}
                  className="text-3xl font-bold text-gray-700 post-it-form"
                />
              </Group>

              <div className="flex items-center justify-center text-center mt-5">
                <Button
                  size="md"
                  variant="subtle"
                  color="green"
                  className="bg-green-50"
                  rightIcon={<CheckSmall size={24} />}
                  onClick={handleCodeVerification}
                >
                  Verify
                </Button>
                <Button
                  onClick={(e) => handleCodeVerification(e, "resend")}
                  variant="default"
                  size="md"
                  mx="sm"
                  className="flex items-center text-red hover:text-primary cursor-pointer border-none"
                >
                  <span className="font-bold">Resend OTP</span>
                  <i className="bx bx-caret-right ml-1"></i>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyAccount;
