import React from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import { MantineProvider } from "@mantine/core";

import "./index.css";
import i18n from "i18n";
import App from "./App";
import store, { persistor } from "./store";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <BrowserRouter>
          <I18nextProvider i18n={i18n}>
            <MantineProvider
              withGlobalStyles
              withNormalizeCSS
              theme={{
                /** Put your mantine theme override here */
                // colorScheme: "light",
                colors: {
                  blue: [
                    "#F3B2B3",
                    "#F0A1A1",
                    "#EB7E7E",
                    "#E55A5B",
                    "#E03738",
                    "#CE2021",
                    "#9D1819",
                    "#6D1111",
                  ],
                },
              }}
            >
              <App />
            </MantineProvider>
          </I18nextProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
