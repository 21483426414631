import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import store from "../store";
import authSlice from "../store/slices/auth";
// import { useHistory } from "react-router-dom";

// const history = useHistory();

const axiosService = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

axiosService.interceptors.request.use(async (config) => {
  const { token } = store.getState().auth;

  if (token !== null) {
    config.headers.Authorization = "Bearer " + token;
    // @ts-ignore
    // console.debug(
    //   "[Request]",
    //   config.baseURL + config.url,
    //   JSON.stringify(token)
    // );
  }
  return config;
});

axiosService.interceptors.response.use(
  (res) => {
    // @ts-ignore
    // console.debug(
    //   "[Response]",
    //   res.config.baseURL + res.config.url,
    //   res.status,
    //   res.data
    // );
    return Promise.resolve(res);
  },
  (err) => {
    // console.debug(
    //   "[Response]",
    //   err.config.baseURL + err.config.url,
    //   err.response.status,
    //   err.response.data
    // );
    return Promise.reject(err);
  }
);

// @ts-ignore
const refreshAuthLogic = async (failedRequest) => {
  const { refreshToken } = store.getState().auth;
  // console.log(store.getState());
  if (refreshToken !== null) {
    return axios
      .post(
        "/accounts/refresh-jwt/",
        {
          refresh: refreshToken,
        },
        {
          baseURL: process.env.REACT_APP_API_URL,
        }
      )
      .then((resp) => {
        // console.log("Access Token refereshed 🚀", resp.data);
        const { access, refresh } = resp.data;
        failedRequest.response.config.headers.Authorization =
          "Bearer " + access;
        store.dispatch(
          authSlice.actions.setAuthTokens({
            token: access,
            refreshToken: refresh,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        // if (err.response && err.response.status === 401) {
        //   store.dispatch(authSlice.actions.logout());
        //   // location.href = "/login";
        //   // console.log("here");
        //   // history.pushState("/login");
        // }
      });
  }
};

createAuthRefreshInterceptor(axiosService, refreshAuthLogic);

export function fetcher(url) {
  return axiosService.get(url).then((res) => res.data);
}

export default axiosService;
