import { lazy } from "react";

import SchoolAccessHOC from "routes/authGuards/SchoolAccessHOC";
import SubgroupLibraryAccessHOC from "routes/authGuards/SubgroupLibraryHOC";
import SubgroupLibraryAdminAccessHOC from "routes/authGuards/SubgroupLibraryAdminHOC";

const SchoolLibrary = lazy(() => import("pages/library/SchoolLibrary"));
const SchoolELibrary = lazy(() => import("pages/library/SchoolELibrary"));
const ResourceDetail = lazy(() =>
  import("pages/library/resources/ResourceDetail")
);

const LibraryHome = lazy(() => import("pages/library/LibraryHome"));
const BookDetail = lazy(() => import("pages/library/books/BookDetail"));
const ManageBookReservations = lazy(() =>
  import("pages/library/reservation/ManageBookReservations")
);
const MyBookReservations = lazy(() =>
  import("pages/library/reservation/MyBookReservations")
);

const libraryRoutes = [
  {
    path: "/schools/:slug/library",
    component: SchoolLibrary,
    title: "School Library",
    authGuard: SchoolAccessHOC,
  },

  {
    path: "/schools/:slug/library-:libraryID",
    component: LibraryHome,
    title: "School Library",
    authGuard: SubgroupLibraryAccessHOC,
  },

  {
    path: "/schools/:slug/library-:libraryID/my-reservations",
    component: MyBookReservations,
    title: "My Book Reservations",
    authGuard: SubgroupLibraryAccessHOC,
  },

  {
    path: "/schools/:slug/library-:libraryID/manage-reservations",
    component: ManageBookReservations,
    title: "Book Reservations",
    authGuard: SubgroupLibraryAdminAccessHOC,
  },

  {
    path: "/schools/:slug/e-library",
    component: SchoolELibrary,
    title: "School E-Library",
    authGuard: SchoolAccessHOC,
  },
  {
    path: "/schools/:schoolSlug/library/resource-:resourceID/details",
    component: ResourceDetail,
    title: "Resource Detail",
    authGuard: SchoolAccessHOC,
  },
];

export default libraryRoutes;

export const publicRoutes = [
  {
    path: "/library/library-:libraryID/book-:bookID/details",
    component: BookDetail,
    title: "Book Details",
  },
];
