import { lazy } from "react";

import GradeAccessHOC from "routes/authGuards/GradeAccessHOC";

const GradeMarksheet = lazy(() => import("pages/marksheet/GradeMarksheet"));

const marksheetRoutes = [
  {
    path: "/schools/:schoolSlug/:gradeSlug/marksheet",
    component: GradeMarksheet,
    title: "Grade Marksheet",
    authGuard: GradeAccessHOC,
  },
];

export default marksheetRoutes;
