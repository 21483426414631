import { lazy } from "react";

// import CourseAdminHOC from "routes/authGuards/CourseAdminHOC";
import ChapterAccessHOC from "routes/authGuards/ChapterAccessHOC";
import SubjectAccessHOC from "routes/authGuards/SubjectAccessHOC";
import TopicAccessHOC from "routes/authGuards/TopicAccessHOC";
import SubtopicAccessHOC from "routes/authGuards/SubtopicAccessHOC";

// use lazy for better code splitting, a.k.a. load faster
// const ManageChapters = lazy(() => import("pages/chapters/ManageChapters"));
const ChapterTaskSubmissions = lazy(() =>
  import("pages/tasks/submissions/ChapterTaskSubmissions")
);
const SubjectTaskSubmissions = lazy(() =>
  import("pages/tasks/submissions/SubjectTaskSubmissions")
);

const chapterRoutes = [
  // {
  //   path: "schools/:schoolSlug/:courseSlug/:slug/contents", // the url
  //   component: ManageChapters, // view rendered
  //   title: "Manage Chapters",
  // },
  {
    path: "schools/:schoolSlug/:courseSlug/:slug/task-:taskId/submissions",
    component: SubjectTaskSubmissions,
    title: "Subject Task Submissions",
    authGuard: SubjectAccessHOC,
  },
  {
    path: "schools/:schoolSlug/:courseSlug/:subjectSlug/:slug/task-:taskId/submissions",
    component: ChapterTaskSubmissions,
    title: "Chapter Task Submissions",
    authGuard: ChapterAccessHOC,
  },

  {
    path: "courses/:courseSlug/:slug/task-:taskId/submissions",
    component: SubjectTaskSubmissions,
    title: "Topic Task Submissions",
    authGuard: TopicAccessHOC,
  },
  {
    path: "courses/:courseSlug/:topicSlug/:slug/task-:taskId/submissions",
    component: ChapterTaskSubmissions,
    title: "Subtopic Task Submissions",
    authGuard: SubtopicAccessHOC,
  },
];

export default chapterRoutes;
