import React from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import * as Yup from "yup";
import { Formik } from "formik";
import toast from "react-hot-toast";
import { Group, PasswordInput, Button, Image } from "@mantine/core";
import { BiReset } from "react-icons/bi";

import LoadingDots from "components/misc/LoadingDots";
import axiosService from "utils/axios";
import { LogoLink } from "layouts/blocks/LogoBlock";
import { Error } from "components/misc/Utility";
import { InputWrap } from "components/misc/Inputs";
import changePasswordSvg from "images/svgs/change-password.svg";

export const getCharacterValidationError = (str) => {
  return `Your password must have at least 1 ${str} character`;
};

const ResetPassword = () => {
  const { uid, token } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const initialValues = {
    password: "",
    confirm_password: "",
  };

  const validation = Yup.object({
    password: Yup.string()
      .required("Please enter a password")
      // check minimum characters
      .min(8, "Password must have at least 8 characters")
      // different error messages for different requirements
      .matches(/[0-9]/, getCharacterValidationError("digit"))
      .matches(/[a-z]/, getCharacterValidationError("lowercase"))
      .matches(/[A-Z]/, getCharacterValidationError("uppercase")),
    confirm_password: Yup.string()
      .required("Please re-type your password")
      // use oneOf to match one of the values inside the array.
      // use "ref" to get the value of passwrod.
      .oneOf([Yup.ref("password")], "Passwords does not match"),
  });

  const confirmReset = (values) => {
    setLoading(true);
    let url = "/users/reset_password_confirm/";
    let data = { new_password: values.password, uid, token };

    axiosService
      .post(url, data)
      .then(() => {
        toast.success("Password reset success!");
        navigate("/login");
      })
      .catch(() => {
        setLoading(false);
        toast.error("Error while confirming password reset!");
      });
  };

  return (
    <div className="bg-primary-500 h-screen overflow-hidden flex items-center justify-center bg-no-repeat bg-center py-12">
      <div className="border">
        <Group
          mb="lg"
          position="center"
          className="backdrop-blur-xl bg-white/50"
        >
          <LogoLink />
        </Group>
        <div className="container mx-auto grid lg:grid-cols-2 px-3 gap-5 mb-5">
          <div className="relative bg-white/70 backdrop-blur-xl shadow-3xl p-3 sm:p-5">
            <div className="flex flex-col items-start">
              <h1 className="text-4xl font-bold mb-2 text-red">
                Reset Password
              </h1>

              <p className="text-left text-slate-600 border-l-2 border-red mt-2 pl-2">
                Plese enter a new password for your account.
              </p>
            </div>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validation}
              onSubmit={(values, { resetForm }) => {
                confirmReset(values);
                resetForm();
              }}
            >
              {(formik) => {
                const { values, handleChange, handleBlur, handleSubmit } =
                  formik;

                return (
                  <form onSubmit={handleSubmit} className="relative mt-3">
                    {loading && <LoadingDots visible />}
                    <div className="flex flex-col space-y-5">
                      <InputWrap label="New Password">
                        <PasswordInput
                          variant="unstyled"
                          size="md"
                          name="password"
                          placeholder="Strong Password"
                          autoComplete="new-password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <Error name="password" />
                      </InputWrap>

                      <InputWrap label="Confirm Password">
                        <PasswordInput
                          variant="unstyled"
                          size="md"
                          name="confirm_password"
                          placeholder="Strong Password"
                          autoComplete="confirm-password"
                          value={values.confirm_password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <Error name="confirm_password" />
                      </InputWrap>

                      <Button
                        type="submit"
                        size="md"
                        variant="light"
                        color="orange"
                        className="text-white hover:bg-red bg-orange-500"
                        rightIcon={<BiReset />}
                      >
                        Confirm Reset
                      </Button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
          <div className="hidden lg:block">
            <Image
              src={changePasswordSvg}
              fit="contain"
              alt="Password Reset SVG"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
