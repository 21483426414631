import { lazy } from "react";

import SchoolAccessHOC from "routes/authGuards/SchoolAccessHOC";

const SchoolClubs = lazy(() => import("pages/schools/clubs/SchoolClubs"));
const ClubDetails = lazy(() => import("pages/schools/clubs/ClubDetails"));
// const ZoomClient = lazy(() => import("pages/liveclass/ZoomClient"));

const clubRoutes = [
  {
    path: "/schools/:schoolSlug/clubs",
    component: SchoolClubs,
    title: "School Clubs",
    authGuard: SchoolAccessHOC,
  },

  {
    path: "/schools/:schoolSlug/club-:clubID",
    component: ClubDetails,
    title: "Club Details",
    authGuard: SchoolAccessHOC,
  },

  // {
  //   path: "/:schoolSlug/club-:clubID/event-:eventID/live-session-:sessionID/zoom-client",
  //   component: ZoomClient,
  //   title: "Event Meeting",
  //   authGuard: SchoolAccessHOC,
  // },
];

export default clubRoutes;
