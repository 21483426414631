import React from "react";
import { useLocation } from "react-router-dom";

import useSWR from "swr";
import { LocalPin, Mail, PhoneTelephone } from "@icon-park/react";

import LogoBlock from "./LogoBlock";
import LoadingDots from "components/misc/LoadingDots";
import { SchoolView, AinaView, AcademyView } from "utils/withInstitution";
import { fetcher } from "utils/axios";
// import { useInstitution } from "store/slices/institution";

const BFooter = () => {
  const location = useLocation();
  // const institution = useInstitution();
  const { data: footerData, error: getFooterDataErr } = useSWR(
    "cms/get-footer-section/?alt=True",
    fetcher
  );

  // footerData && console.log(footerData);
  getFooterDataErr && console.log(getFooterDataErr);

  return (
    <footer className="px-4 pt-8 space-y-8 bg-gray-50 divide-y sm:pt-10 sm:px-6 lg:px-8 divide-gray-400/20">
      {/* <!-- Top section: blocks --> */}
      <div className="grid max-w-md mx-auto gap-y-8 sm:gapy-12 sm:gap-x-8 md:gap-x-12 sm:max-w-none lg:max-w-screen-2xl sm:grid-cols-2 lg:grid-cols-11 lg:gap-8 xl:gap-12">
        {/* <!-- Block 1 --> */}
        <div className="flex flex-col lg:mx-auto lg:col-span-4">
          {/* <!-- Logo --> */}
          <LogoBlock />

          {/* <!-- Mission statement --> */}
          <div className="mt-6 text-lg text-justify text-red-800">
            {location.pathname === "/" ? (
              <p>
                UED is the educational institution and Ed-Tech platform of KAii
                Group. We offer academic and alternative courses of different
                fields as well as research and innovation space. We bring
                unlimited education for all.
              </p>
            ) : (
              <>
                <SchoolView>
                  Based on holistic curriculum and programs, Ued Schools is a
                  group of schools globally with Moto of Global standard
                  education for all.
                </SchoolView>
                <AinaView>
                  We are the institute for the social and educational sciences
                  of the UED. We offer quality, flexible and inclusive trainings
                  to help you create a positive impact.
                </AinaView>
                <AcademyView>
                  Alternative education and learning academy for skill
                  development, training and broadening your palette.
                </AcademyView>
              </>
            )}
          </div>
        </div>

        {/* <!-- Block 2 --> */}
        {footerData ? (
          footerData.map((section) => (
            <div
              key={section.id}
              className="flex-shrink sm:order-3 lg:order-none lg:col-span-2 text-left"
            >
              <h6 className="relative text-xl font-bold tracking-wide">
                <span className="relative z-20 text-red">{section.title}</span>
                <span className="absolute left-0 z-10 w-14 h-1 rounded-lg bg-gradient-to-r from-red to-primary -bottom-1"></span>
              </h6>
              <ul className="mt-6 text-lg divide-y divide-red-400/20">
                {section.footer_links.map((item) => (
                  <li
                    key={item.id}
                    className="py-2 font-medium bg-clip-text text-transparent bg-gradient-to-r from-red to-primary hover:text-red duration-300 ease-in-out hover:bg-gradient-to-l"
                  >
                    <a href={item.link_url}>{item.link_label}</a>
                  </li>
                ))}
              </ul>
            </div>
          ))
        ) : (
          <LoadingDots visible />
        )}

        {/* <!-- Block 3 --> */}
        {/* <div className="flex-shrink sm:order-4 lg:order-none lg:col-span-2">
          <h6 className="relative text-xl font-bold tracking-wide">
            <span className="relative z-20 text-red">Site Links</span>
            <span className="absolute left-1/2 z-10 w-12 h-1 rounded-lg bg-gradient-to-l from-red to-primary -bottom-1"></span>
          </h6>
          <ul className="mt-6 text-lg divide-y divide-red-400/20">
            <li className="pb-2 font-medium text-red-700 duration-300 ease-in-out hover:text-red-600">
              <a href="index.html">Home</a>
            </li>
            <li className="py-2 font-medium text-red-700 duration-300 ease-in-out hover:text-red-600">
              <a href="about-us.html">About us</a>
            </li>
            <li className="py-2 font-medium text-red-700 duration-300 ease-in-out hover:text-red-600">
              <a href="gallery.html">Gallery</a>
            </li>
            <li className="py-2 font-medium text-red-700 duration-300 ease-in-out hover:text-red-600">
              <a href="parents.html">Parents</a>
            </li>
            <li className="pt-2 font-medium text-red-700 duration-300 ease-in-out hover:text-red-600">
              <a href="contact.html">Contact us</a>
            </li>
          </ul>
        </div> */}

        {/* <!-- Block 4 --> */}
        <div className="sm:order-2 lg:order-none lg:col-span-3 lg:mx-auto ">
          <h6 className="relative text-xl font-bold tracking-wide text-left">
            <span className="relative z-20 text-red">Contact us</span>
            <span className="absolute left-0 z-10 w-12 h-1 rounded-lg bg-gradient-to-r from-red to-primary -bottom-1"></span>
          </h6>

          {/* <!-- Contact information --> */}
          <ul className="flex flex-col mt-6 space-y-5">
            {/* <!-- Address --> */}
            <li className="flex items-center flex-shrink max-w-xs">
              <div className="flex items-center justify-center bg-gradient-to-t from-red to-primary rounded w-11 h-11">
                <LocalPin theme="outline" size="24" fill="#FFF" />
              </div>
              <div className="flex-1 ml-3 xl:ml-4">
                <h5 className="flex items-center text-base font-semibold text-red-900">
                  Barcelona, Spain
                </h5>
              </div>
            </li>

            {/* <!-- Email --> */}
            <li className="flex items-center flex-shrink-0">
              <div className="flex items-center justify-center bg-gradient-to-b from-red to-primary rounded w-11 h-11">
                <Mail theme="outline" size="24" fill="#FFF" />
              </div>
              <div className="flex-1 ml-3 xl:ml-4">
                <p className="text-left font-semibold">info@ued.ai</p>
                {location.pathname !== "/" && (
                  <h5 className="flex items-center text-base font-semibold text-red-900">
                    <SchoolView>info@uedschools.com</SchoolView>
                  </h5>
                )}
              </div>
            </li>

            {/* <!-- Phone number --> */}
            <li className="flex items-center flex-shrink-0">
              <div className="flex items-center justify-center rounded w-11 h-11 bg-gradient-to-t from-red to-primary">
                <PhoneTelephone theme="outline" size="24" fill="#FFF" />
              </div>
              <div className="flex-1 ml-3 xl:ml-4">
                <h5 className="flex items-center text-base font-semibold text-red-900">
                  +34663778126
                </h5>
              </div>
            </li>
          </ul>
        </div>
      </div>

      {/* <!-- Bottom section --> */}
      <div className="flex flex-col items-center justify-between max-w-md py-3 mx-auto sm:flex-row sm:max-w-none lg:max-w-screen-2xl bg-gradient-to-r from-gray-50 via-gray-200 to-gray-50">
        {/* <!-- Copyright note --> */}
        <span className="text-base text-red-800/90">
          © 2022 UED. All rights reserved.
        </span>

        {/* <!-- Social links --> */}
        <div className="mt-5 lg:mt-6">
          <div className="flex justify-start space-x-4">
            {/* <!-- Instagram --> */}
            <a
              className="flex items-center justify-center w-10 h-10 text-white duration-300 ease-in-out bg-red-500 rounded-full hover:bg-red-600"
              href="/"
            >
              {/* <!-- TablerIcon name: brand-instagram --> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5"
                width="44"
                height="44"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <rect x="4" y="4" width="16" height="16" rx="4" />
                <circle cx="12" cy="12" r="3" />
                <line x1="16.5" y1="7.5" x2="16.5" y2="7.501" />
              </svg>
            </a>

            {/* <!-- Facebook --> */}
            <a
              className="flex items-center justify-center w-10 h-10 text-white duration-300 ease-in-out bg-red-500 rounded-full hover:bg-red-600"
              href="/"
            >
              {/* <!-- TablerIcon name: brand-facebook --> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5"
                width="44"
                height="44"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
              </svg>
            </a>

            {/* <!-- Twitter --> */}
            <a
              className="flex items-center justify-center w-10 h-10 text-white transition duration-300 ease-in-out bg-red-500 rounded-full hover:bg-red-600"
              href="/"
            >
              {/* <!-- TablerIcon name: brand-twitter --> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5"
                width="44"
                height="44"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M22 4.01c-1 .49 -1.98 .689 -3 .99c-1.121 -1.265 -2.783 -1.335 -4.38 -.737s-2.643 2.06 -2.62 3.737v1c-3.245 .083 -6.135 -1.395 -8 -4c0 0 -4.182 7.433 4 11c-1.872 1.247 -3.739 2.088 -6 2c3.308 1.803 6.913 2.423 10.034 1.517c3.58 -1.04 6.522 -3.723 7.651 -7.742a13.84 13.84 0 0 0 .497 -3.753c-.002 -.249 1.51 -2.772 1.818 -4.013z" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default BFooter;
