import { lazy } from "react";

import StudentParentHOC from "routes/authGuards/StudentParentHOC";

// use lazy for better code splitting, a.k.a. load faster
const ManageAccounts = lazy(() => import("pages/users/ManageAccounts"));
const MyAccount = lazy(() => import("pages/users/MyAccount"));
const UpdateUser = lazy(() => import("pages/users/UpdateUser"));
const AddUser = lazy(() => import("pages/users/AddUser"));
const LearningHistory = lazy(() =>
  import("pages/users/students/LearningHistory")
);
const EnrollmentAnalytics = lazy(() =>
  import("pages/users/students/EnrollmentAnalytics")
);

const userRoutes = [
  {
    path: "users/manage-accounts",
    component: ManageAccounts,
    title: "Manage User Accounts",
    permission: ["Admin"],
  },
  {
    path: "users/my-account",
    component: MyAccount,
    title: "My Account",
  },
  {
    path: "users/user-:userID/update",
    component: UpdateUser,
    title: "Update User",
    permission: ["Admin"],
  },
  {
    path: "users/add",
    component: AddUser,
    title: "Add New User",
    permission: ["Admin"],
  },
  {
    path: "my-learning-history",
    component: LearningHistory,
    title: "My Learning History",
    permission: ["Student"],
  },
  {
    path: "users/student-:studentID/learning-history",
    component: LearningHistory,
    title: "Learning History",
    authGuard: StudentParentHOC,
  },
  {
    path: "users/student-:studentID/enroll-:enrollID/analytics",
    component: EnrollmentAnalytics,
    title: "Student Analytics",
    authGuard: StudentParentHOC,
  },
];

export default userRoutes;
