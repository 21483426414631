import { School, AllApplication } from "@icon-park/react";
import { EmailDelect, Classroom, History } from "@icon-park/react";

import { Icon } from "utils/Icon";

// const dashboardLinks = {
//   school: "/schools/dashboard",
//   aina: "/aina-institute/dashboard",
//   holistic: "/holistic/dashboard",
// };

export const barItems = [
  {
    label: "Institution",
    url: "/home",
    icon: <Icon nameIcon="TbHome" propsIcon={{ size: 24 }} />,
  },
  {
    label: "Dashboard",
    url: "/dashboard",
    // url: dashboardLinks,
    icon: <AllApplication size="22" />,
    // is_dynamic: true,
    // institutions: ["school", "holistic"],
  },
  {
    label: "Users",
    url: "/users/manage-accounts",
    icon: <Icon nameIcon="TbUsers" propsIcon={{ size: 24 }} />,
    roles: ["Admin"],
  },
  {
    label: "Groups",
    url: "/manage-groups",
    icon: <Icon nameIcon="RiStackLine" propsIcon={{ size: 24 }} />,
    roles: ["Admin"],
  },
  {
    label: "Schools",
    url: "/manage-schools",
    icon: <School size="24" />,
    roles: ["Admin", "Group Manager"],
    institutions: ["school"],
  },
  {
    label: "Courses",
    url: "/courses",
    icon: <Icon nameIcon="AiOutlineRead" propsIcon={{ size: 24 }} />,
    // roles: [
    //   "Admin",
    //   "Group Manager",
    //   "SubGroup Manager",
    //   "Supervisor",
    //   "Teacher",
    //   "Student",
    // ],
    institutions: ["aina", "academy", "all"],
  },
  {
    label: "Sites",
    url: "/partner-sites",
    icon: <Icon nameIcon="TbLocation" propsIcon={{ size: 24 }} />,
    roles: ["Admin"],
  },

  {
    label: "Blogs",
    url: "/manage-blogs",
    icon: <Icon nameIcon="FaBlog" propsIcon={{ size: 24 }} />,
    roles: ["Admin", "Group Manager"],
  },

  {
    label: "Classrooms",
    url: "/staff-classrooms",
    icon: <Classroom size="24" />,
    roles: ["Supervisor", "Teacher"],
    institutions: ["school"],
  },
  {
    label: "Invitations",
    url: "/staff-invitations",
    icon: <EmailDelect size="24" />,
    roles: ["Supervisor", "Teacher"],
  },

  {
    label: "My Parents",
    url: "/schools/student/my-parents",
    icon: <Icon nameIcon="RiParentLine" propsIcon={{ size: 24 }} />,
    roles: ["Student"],
    institutions: ["school"],
  },

  {
    label: "Learning History",
    url: "/my-learning-history",
    icon: <History size="24" />,
    roles: ["Student"],
  },
  {
    label: "Invitations",
    url: "/parents/my-invitations",
    icon: <EmailDelect size="24" />,
    roles: ["Parent"],
    institutions: ["school"],
  },

  {
    label: "CMS",
    url: "/cms",
    icon: <Icon nameIcon="CgWebsite" propsIcon={{ size: 24 }} />,
    roles: ["Admin"],
  },

  {
    label: "More",
    url: "/more",
    icon: <Icon nameIcon="CgMoreO" propsIcon={{ size: 24 }} />,
    roles: ["Admin", "Student"],
  },
];
