import React, { useState, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

import * as Yup from "yup";
import axios from "axios";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import { Transition, PasswordInput } from "@mantine/core";
import { User, Lock } from "@icon-park/react";
import { Image } from "@mantine/core";

import VerifyAccount from "components/auth/VerifyAccount";
import LoadingDots from "components/misc/LoadingDots";
import authSlice from "store/slices/auth";
import institutionSlice from "store/slices/institution";
import logo from "images/logo.png";
import { useInstitution } from "store/slices/institution";
// import { TextInput } from "components/misc/Inputs";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const institution = useInstitution();
  const [showForm, setShowForm] = useState(false);
  const [promptVerification, setPromptVerification] = useState(false);
  const [loading, setLoading] = useState(false);

  useLayoutEffect(() => {
    setShowForm(true);
  }, []);

  const resetToLogin = () => {
    setPromptVerification(false);
  };

  const handleLogin = (email, password) => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/accounts/user-login/`, {
        email,
        password,
      })
      .then((res) => {
        if (res.data.user.is_active) {
          dispatch(
            authSlice.actions.setAuthTokens({
              token: res.data.access,
              refreshToken: res.data.refresh,
            })
          );
          dispatch(authSlice.actions.setAccount(res.data.user));
          dispatch(authSlice.actions.setRole(res.data.user?.roles[0]));
          if (!institution)
            dispatch(institutionSlice.actions.setInstitution({ id: "school" }));
          // setLoading(false);
          // console.log(res);
          toast.success("Login Success!");
          setShowForm(false);
          navigate("/home", { replace: true });
        } else {
          setLoading(false);
          setPromptVerification(true);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.detail ?? "Login Error!");
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      // setLoading(true);
      handleLogin(values.email, values.password);
      // console.log(values);
    },
    validationSchema: Yup.object({
      email: Yup.string().trim().required("Required"),
      password: Yup.string().trim().required("Required"),
    }),
  });

  return (
    <div className="bg-primary-500 h-screen overflow-hidden flex items-center justify-center bg-[url('images/svgs/login.svg')] bg-no-repeat bg-center">
      <Transition
        mounted={showForm}
        transition="slide-down"
        duration={500}
        timingFunction="ease"
      >
        {(styles) => (
          <div
            style={styles}
            className="relative backdrop-blur-xl bg-white/50 lg:w-5/12 md:6/12 w-10/12 shadow-3xl"
          >
            {/* <div className="bg-white/60 backdrop-blur-sm absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded p-3">
          <svg width="32" height="32" viewBox="0 0 24 24" fill="#FFF">
            <path d="M0 3v18h24v-18h-24zm6.623 7.929l-4.623 5.712v-9.458l4.623 3.746zm-4.141-5.929h19.035l-9.517 7.713-9.518-7.713zm5.694 7.188l3.824 3.099 3.83-3.104 5.612 6.817h-18.779l5.513-6.812zm9.208-1.264l4.616-3.741v9.348l-4.616-5.607z" />
          </svg>
        </div> */}
            {loading && <LoadingDots visible />}
            <div className="flex justify-center pt-5">
              <Image
                onClick={() => navigate("/")}
                className="cursor-pointer"
                src={logo}
                alt="UED Logo"
                width={120}
              />
            </div>
            {promptVerification ? (
              formik.values.email && (
                <VerifyAccount
                  reset={resetToLogin}
                  email={formik.values.email}
                />
              )
            ) : (
              <form
                onSubmit={formik.handleSubmit}
                className="px-5 pb-8 pt-3 sm:pt-0 md:px-24 md:py-10"
              >
                <div className="mb-6 md:mb-8">
                  {/* <TextInput
                  size="md"
                  label="Email"
                  name="country"
                  type="text"
                  placeholder="Email Address"
                  className="placeholder:text-dark!"
                /> */}
                  <div className="flex items-center text-lg w-full">
                    <User
                      className="absolute ml-3"
                      theme="filled"
                      size="24"
                      fill="#333"
                    />
                    <input
                      type="email"
                      name="email"
                      className="bg-white pl-12 py-2 md:py-4 focus:outline-none w-full placeholder:font-semibold"
                      placeholder="Email Address"
                      autoComplete="username"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  {formik.touched.email && formik.errors.email && (
                    <div className="font-semibold text-red-500 mt-2">
                      {formik.errors.email}{" "}
                    </div>
                  )}
                </div>

                <div className="mb-6 md:mb-8">
                  <div className="flex items-center text-lg">
                    <Lock
                      className="absolute ml-3 -mt-1"
                      theme="filled"
                      size="24"
                      fill="#333"
                    />
                    <PasswordInput
                      variant="unstyled"
                      // type="password"
                      name="password"
                      classNames={{
                        root: "bg-white backdrop-white pl-9 py-1 md:pb-2.5 md:pt-3 focus:outline-none w-full",
                        innerInput: "text-lg placeholder:font-semibold",
                      }}
                      placeholder="Password"
                      autoComplete="current-password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>

                  {formik.touched.password && formik.errors.password && (
                    <div className="font-semibold text-red-500 mt-2">
                      {formik.errors.password}{" "}
                    </div>
                  )}
                </div>

                <div className="flex justify-between mb-3">
                  <label className="flex items-center text-gray-500 font-bold">
                    <input
                      type="checkbox"
                      className="leading-loose text-pink-600"
                    />
                    <span className="text-sm text-gray-700 leading-snug ml-2">
                      {" "}
                      Remember Me{" "}
                    </span>
                  </label>
                  <label className="block text-gray-500 font-bold">
                    {/* <a
                      href=""
                      className="cursor-pointer tracking-tighter text-red border-b-2 border-gray-200 hover:border-gray-400"
                    > */}
                    <Link
                      to="/reset-password"
                      className="text-orange-500 hover:text-red"
                    >
                      Forgot Password?
                    </Link>
                    {/* </a> */}
                  </label>
                </div>

                <div className="relative mb-5 sm:mb-0">
                  <span className="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-black rounded"></span>
                  <button
                    type="submit"
                    className="relative inline-block w-full h-full p-3 text-base font-bold transition duration-100 bg-white border-2 border-black rounded fold-bold hover:bg-yellow-400 hover:text-gray-900"
                  >
                    Login
                  </button>
                </div>

                <div className="flex justify-center mt-5">
                  <Link
                    to="/signup"
                    className="flex items-center hover:text-red"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                      />
                    </svg>
                    <span className="border-b-2 border-primary-300 hover:border-red-400">
                      I don't have an account
                    </span>
                  </Link>
                </div>
              </form>
            )}
          </div>
        )}
      </Transition>

      {/* <Transition
        mounted={promptVerification}
        transition="slide-down"
        duration={500}
        timingFunction="ease"
      >
        {(styles) => (
          <div
            style={styles}
            className="relative backdrop-blur-xl bg-white/50 lg:w-5/12 md:6/12 w-10/12 shadow-3xl"
          >
            {formik.values.email && (
              <VerifyAccount email={formik.values.email} />
            )}
          </div>
        )}
      </Transition> */}
    </div>
  );
};

export default Login;
