import React, { useState, useLayoutEffect } from "react";
import { Navigate, useParams } from "react-router-dom";

import useSWR from "swr";
import toast from "react-hot-toast";

import { fetcher } from "utils/axios";

const SubgroupAdminHOC = ({ children }) => {
  const { subgroupSlug, slug } = useParams();
  // const [hasChecked, setHasChecked] = useState(false);
  const [hasAccess, setHasAccess] = useState(true);
  const { data: accessData, error: checkAccessErr } = useSWR(
    `/groups/subgroup/${subgroupSlug ?? slug}/check-admin-access/`,
    fetcher
  );

  useLayoutEffect(() => {
    if (!checkAccessErr) return;
    if (checkAccessErr) {
      setHasAccess(false);
      toast.error("Unable to validate access!");
    }
    accessData && !accessData.has_access && setHasAccess(false);
  }, [accessData, checkAccessErr]);

  return <>{hasAccess ? children : <Navigate to="/home" />}</>;
};

export default SubgroupAdminHOC;
