import { lazy } from "react";

import SchoolAdminHOC from "routes/authGuards/SchoolAdminHOC";
import GradeAccessHOC from "routes/authGuards/GradeAccessHOC";

// const SchoolStaff = lazy(() => import("pages/schools/staff/SchoolStaff"));
const SubgroupStaff = lazy(() => import("pages/groups/staff/SubgroupStaff"));
const StaffInvitations = lazy(() =>
  import("pages/schools/staff/StaffInvitations")
);
const StaffClassrooms = lazy(() =>
  import("pages/schools/staff/StaffClassrooms")
);
const GradeDiscussions = lazy(() =>
  import("pages/schools/student/GradeDiscussions")
);
const GradeChats = lazy(() => import("pages/schools/student/GradeChats"));

const staffRoutes = [
  {
    path: "/staff-invitations", // the url
    component: StaffInvitations, // view rendered
    title: "Staff Invitations",
    permission: ["Supervisor", "Teacher"],
  },

  {
    path: "/staff-classrooms", // the url
    component: StaffClassrooms, // view rendered
    title: "Staff Classrooms",
    permission: ["Supervisor", "Teacher"],
  },

  {
    path: "/schools/:schoolSlug/:gradeSlug/discussions", // the url
    component: GradeDiscussions, // view rendered
    title: "Student Discussions",
    permission: ["Supervisor", "Teacher"],
    authGuard: GradeAccessHOC,
  },
  {
    path: "/schools/:schoolSlug/:gradeSlug/chats", // the url
    component: GradeChats, // view rendered
    title: "Private Chats",
    permission: ["Supervisor", "Teacher"],
    authGuard: GradeAccessHOC,
  },

  // {
  //   path: "/schools/:slug/staff-area", // the url
  //   component: SchoolStaff, // view rendered
  //   title: "School Staff Area",
  //   permission: ["Admin", "Group Manager", "SubGroup Manager"],
  //   authGuard: SchoolAdminHOC,
  // },
  {
    path: "/:groupSlug/:slug/staff-area", // the url
    component: SubgroupStaff, // view rendered
    title: "Subgroup Staff Area",
    permission: ["Admin", "Group Manager", "SubGroup Manager"],
    authGuard: SchoolAdminHOC,
  },
];

export default staffRoutes;
