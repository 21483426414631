import { lazy } from "react";

const SchoolCalendar = lazy(() =>
  import("pages/schools/calendar/SchoolCalendar")
);
const GradeCalendar = lazy(() =>
  import("pages/schools/calendar/GradeCalendar")
);

const SchoolNoticeboard = lazy(() =>
  import("pages/schools/noticeboard/SchoolNoticeboard")
);

const calendarRoutes = [
  {
    path: "schools/:schoolSlug/calendar",
    component: SchoolCalendar,
    title: "School Calendar",
    // permission: ["Teacher", "Supervisor"],
    // authGuard: GradeAccessHOC,
  },
  {
    path: ":schoolSlug/:gradeSlug/calendar",
    component: GradeCalendar,
    title: "Grade Calendar",
    // permission: ["Teacher", "Supervisor"],
    // authGuard: GradeAccessHOC,
  },
  {
    path: "schools/:schoolSlug/noticeboard",
    component: SchoolNoticeboard,
    title: "School Noticeboard",
    // permission: ["Teacher", "Supervisor"],
    // authGuard: GradeAccessHOC,
  },
];

export default calendarRoutes;
