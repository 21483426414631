import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import useSWR from "swr";
import toast from "react-hot-toast";
import * as Yup from "yup";
// import PhoneInput from "react-phone-number-input/input";
import { useFormik } from "formik";
import { Group, Stepper, Button, Tooltip } from "@mantine/core";
import { LeftSmall, ArrowRight, CheckOne, User, Check } from "@icon-park/react";
import { SendEmail, Lock, LocalTwo, PhoneTelephone } from "@icon-park/react";
import { getCountryCallingCode } from "react-phone-number-input/input";

import "react-phone-number-input/style.css";
import en from "react-phone-number-input/locale/en.json";
import logo from "images/logo.png";
import CountrySelect from "components/misc/CountrySelect";
import VerifyAccount from "components/auth/VerifyAccount";
import LoadingDots from "components/misc/LoadingDots";
import axiosService, { fetcher } from "utils/axios";
import { ErrorMsg } from "components/schools/staff/NewSupervisor";
import { TextInput, PasswordInput } from "components/misc/Inputs";

const Signup = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [rolesData, setRolesData] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [disableNext, setDisableNext] = useState(true);
  const [loading, setLoading] = useState(false);
  const { data: initialData, error: getInitialErr } = useSWR(
    `/accounts/signup-form-initial`,
    fetcher
  );

  useEffect(() => {
    if (!initialData) return;
    let roles = [];
    initialData.roles.forEach((item) => {
      // console.log(option)
      roles.push({
        label: item.title,
        value: item.id.toString(),
      });
    });
    // console.log(activeOptions);
    setRolesData(roles);
  }, [initialData]);

  getInitialErr && toast.error("Error while loading initial data!");
  // initialData && console.log(initialData);

  const nextStep = () =>
    setActiveStep((current) => (current < 3 ? current + 1 : current));

  const prevStep = () =>
    setActiveStep((current) => (current > 0 ? current - 1 : current));

  const userSchema = Yup.object().shape({
    role: Yup.string().required("Required"),
    username: Yup.string().required("Required"),
    email: Yup.string().email().required("Required"),
    password: Yup.string().required("Required"),
    confirm_password: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
    country_code: Yup.string().required("Required"),
    contact_number: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      role: "",
      username: "",
      email: "",
      password: "",
      confirm_password: "",
      country: "",
      country_code: "",
      country_prefix: "",
      contact_number: "",
    },
    enableReinitialize: true,
    onSubmit: (values, resetForm) => {
      // console.log(values);
      performAdd(values, resetForm);
    },
    validationSchema: userSchema,
  });

  const performAdd = (values, { resetForm }) => {
    setLoading(true);
    if (Object.keys(formik.errors) > 0) return;
    let url = `/accounts/signup/`;
    let data = { ...values };

    axiosService
      .post(url, data)
      .then(() => {
        // console.log(res);
        toast.success("Account Created!");
        // mutate();
        setUserEmail(formik.values.email);
        resetForm();
        setActiveStep(activeStep + 1);
        setFormSubmitted(true);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        toast.error("Unable to create user!");
      });
  };

  useEffect(() => {
    if (activeStep === 0) {
      setDisableNext(formik.values.role === "");
    }
  }, [activeStep, formik.values]);

  // useEffect(() => {
  //   if (Object.keys(formik.errors).length === 0) return;
  //   if (formik.errors.role) {
  //     setActiveStep(0);
  //   } else {
  //     setActiveStep(1);
  //   }
  // }, [formik.errors]);

  const handleRoleSelect = (role) => {
    formik.setFieldValue("role", role.value);
    setSelectedRole(role.label);
  };

  const handleCountrySelect = (code) => {
    formik.setFieldValue("country_code", code);
    formik.setFieldValue("country", en[code]);
    formik.setFieldValue("country_prefix", getCountryCallingCode(code));
  };

  return (
    <div className="min-h-screen bg-[url('images/svgs/nodata.svg')]">
      <div className="relative py-16 backdrop-blur-3xl bg-white/10 min-h-screen">
        <div className="relative container m-auto px-6 text-gray-500 md:px-12 xl:px-40">
          <div className="m-auto space-y-8 md:w-11/12">
            <Group position="center">
              <Link to="/">
                <img
                  src={logo}
                  loading="lazy"
                  className="w-36 ml-4"
                  alt="UED Logo"
                />
              </Link>
            </Group>
            <div className="rounded border bg-white/90 shadow-inner">
              <div className="p-6 sm:p-16">
                <h2 className="mb-8 text-3xl bg-clip-text bg-gradient-to-r from-red to-primary text-transparent font-extrabold">
                  Create new account
                </h2>
                <form
                  onSubmit={formik.handleSubmit}
                  className="relative space-y-8"
                >
                  {loading && <LoadingDots visible />}
                  <Stepper
                    active={activeStep}
                    onStepClick={setActiveStep}
                    breakpoint="lg"
                    color="yellow"
                  >
                    <Stepper.Step
                      label="User role"
                      description="Select a role"
                      allowStepSelect={!formSubmitted}
                    >
                      <p className="text-lg text-left text-gray-700 flex justify-between font-bold">
                        <span>Please select your user role:</span>{" "}
                        <span className="text-xl">{selectedRole}</span>
                      </p>
                      <div className="flex flex-wrap w-full">
                        {rolesData.length > 0 &&
                          rolesData.map((role, index) => (
                            <div key={index} className="mt-3 w-full md:w-1/2">
                              <div
                                onClick={() => handleRoleSelect(role)}
                                className={`sm:text-lg ml-2 font-bold shadow-lg border-2 rounded flex p-3 items-center hover:shadow-inner transition-all delay-100 ease-in-out cursor-pointer ${
                                  formik.values.role === role.value
                                    ? "text-white bg-gradient-to-r from-red to-primary border-none justify-between"
                                    : "text-black border-black border-b-4 border-l-4 scale-95"
                                }`}
                              >
                                <span>{role.label}</span>
                                {formik.values.role === role.value && (
                                  <Check size={20} />
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                    </Stepper.Step>
                    <Stepper.Step
                      label="Account Credentials"
                      description="Enter primary details"
                      allowStepSelect={!formSubmitted}
                    >
                      <div className="flex flex-wrap">
                        <div className="w-full lg:w-1/2 lg:px-3 md:px-5 xl:my-1">
                          <TextInput
                            size="md"
                            name="username"
                            type="text"
                            label="Username"
                            autoComplete="username"
                            placeholder="john doe"
                            value={formik.values.username}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />

                          {formik.touched.username &&
                            formik.errors.username && (
                              <ErrorMsg>{formik.errors.username} </ErrorMsg>
                            )}
                        </div>

                        <div className="w-full lg:w-1/2 lg:px-3 md:px-5 xl:my-1">
                          <TextInput
                            size="md"
                            name="email"
                            type="email"
                            label="Email"
                            autoComplete="username"
                            placeholder="a valid email address"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />

                          {formik.touched.email && formik.errors.email && (
                            <ErrorMsg>{formik.errors.email} </ErrorMsg>
                          )}
                        </div>

                        <div className="w-full lg:w-1/2 lg:px-3 md:px-5 xl:my-1">
                          <PasswordInput
                            size="md"
                            name="password"
                            label="Password"
                            placeholder="a strong password"
                            autoComplete="new-password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />

                          {formik.touched.password &&
                            formik.errors.password && (
                              <ErrorMsg>{formik.errors.password} </ErrorMsg>
                            )}
                        </div>

                        <div className="w-full lg:w-1/2 lg:px-3 md:px-5 xl:my-1">
                          <PasswordInput
                            size="md"
                            name="confirm_password"
                            placeholder="re-type password"
                            label="Confirm Password"
                            autoComplete="new-password"
                            value={formik.values.confirm_password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />

                          {formik.touched.confirm_password &&
                            formik.errors.confirm_password && (
                              <ErrorMsg>
                                {formik.errors.confirm_password}{" "}
                              </ErrorMsg>
                            )}
                        </div>
                        <div className="w-full lg:w-1/2 lg:px-3 md:px-5 xl:my-1">
                          <label className="relative block p-3 border-2 mt-5 border-black rounded">
                            <span className="text-md font-semibold text-zinc-900">
                              Country
                            </span>
                            <CountrySelect
                              labels={en}
                              name="country_code"
                              className="w-full my-2 pt-1 text-lg border-none bg-transparent text-gray-600 focus:outline-none"
                              value={formik.values.country_code}
                              onChange={(value) => handleCountrySelect(value)}
                            />
                          </label>

                          {formik.touched.country_code &&
                            formik.errors.country_code && (
                              <ErrorMsg>{formik.errors.country_code} </ErrorMsg>
                            )}
                        </div>
                        <div className="w-full lg:w-1/2 lg:px-3 md:px-5 xl:my-1">
                          <TextInput
                            type="tel"
                            size="md"
                            name="contact_number"
                            label="Contact Number"
                            placeholder="active number"
                            value={formik.values.contact_number}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {/* <label className="relative block p-3 border-2 mt-5 border-black rounded">
                            <span className="text-md font-semibold text-zinc-900">
                              Contact Number
                            </span>
                            <PhoneInput
                              international
                              placeholder="Enter phone number"
                              value={formik.values.contact_number}
                              onChange={(value) =>
                                formik.setFieldValue("contact_number", value)
                              }
                              className="w-full p-0 text-lg border-none bg-transparent text-gray-600 focus:outline-none"
                            />
                          </label> */}

                          {formik.touched.contact_number &&
                            formik.errors.contact_number && (
                              <ErrorMsg>
                                {formik.errors.contact_number}{" "}
                              </ErrorMsg>
                            )}
                        </div>
                      </div>
                    </Stepper.Step>
                    <Stepper.Step
                      label="Review"
                      description="Submit and Verify"
                    >
                      <ReviewDetails
                        user={formik.values}
                        userRole={selectedRole}
                      />
                    </Stepper.Step>
                    <Stepper.Completed>
                      {userEmail && <VerifyAccount email={userEmail} />}
                    </Stepper.Completed>
                  </Stepper>

                  {!formSubmitted && (
                    <Group mt="xl" className="justify-between">
                      {activeStep > 0 && (
                        <Button
                          type="button"
                          onClick={prevStep}
                          variant="subtle"
                          color="orange"
                          leftIcon={<LeftSmall size={24} />}
                        >
                          Back
                        </Button>
                      )}
                      {activeStep < 2 ? (
                        <Button
                          type="button"
                          disabled={disableNext}
                          onClick={nextStep}
                          variant="subtle"
                          color="teal"
                          className="bg-teal-50"
                          rightIcon={<ArrowRight size={24} />}
                        >
                          Next step
                        </Button>
                      ) : (
                        <Button
                          size="lg"
                          type="submit"
                          variant="subtle"
                          color="teal"
                          rightIcon={<CheckOne size={24} />}
                          className="bg-teal-50 text-lg"
                        >
                          Submit
                        </Button>
                      )}
                    </Group>
                  )}
                </form>
              </div>
            </div>
            <div className="text-center space-x-4 pb-5">
              <span>&copy; UED</span>
              <a href="/" className="text-sm hover:text-sky-900">
                Contact
              </a>
              <a href="/" className="text-sm hover:text-sky-900">
                Privacy & Terms
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;

const ReviewDetails = ({ user, userRole }) => (
  <section className="text-gray-600 body-font">
    <div className="container sm:py-3 mx-auto">
      <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
        <div className="p-2 lg:w-1/2 w-full">
          <div className="bg-gray-100 rounded flex p-4 h-full items-center">
            <span className="font-bold">Role:</span>
            <span className="title-font font-medium ml-2">{userRole}</span>
          </div>
        </div>
        <div className="p-2 lg:w-1/2 w-full">
          <div className="bg-gray-100 rounded flex p-4 h-full items-center">
            <Tooltip label="Username">
              <User size={24} />
            </Tooltip>
            <span className="title-font font-medium ml-2">{user.username}</span>
          </div>
        </div>
        <div className="p-2 lg:w-1/2 w-full">
          <div className="bg-gray-100 rounded flex p-4 h-full items-center">
            <Tooltip label="Email Address">
              <SendEmail size={24} />
            </Tooltip>
            <span className="title-font font-medium ml-2 whitespace-normal">
              {user.email}
            </span>
          </div>
        </div>
        <div className="p-2 lg:w-1/2 w-full">
          <div className="bg-gray-100 rounded flex p-4 h-full items-center">
            <Tooltip label="Password">
              <Lock size={24} />
            </Tooltip>
            <span className="title-font font-medium ml-2">{user.password}</span>
          </div>
        </div>
        <div className="p-2 lg:w-1/2 w-full">
          <div className="bg-gray-100 rounded flex p-4 h-full items-center">
            <Tooltip label="Country">
              <LocalTwo size={24} />
            </Tooltip>
            <span className="title-font font-medium ml-2">
              {user.country} {user.country_code && `(${user.country_code})`}
            </span>
          </div>
        </div>
        <div className="p-2 lg:w-1/2 w-full">
          <div className="bg-gray-100 rounded flex p-4 h-full items-center">
            <Tooltip label="Contact Number">
              <PhoneTelephone size={24} />
            </Tooltip>
            <span className="title-font font-medium ml-2">
              {user.country_prefix && `(${user.country_prefix})`}{" "}
              {user.contact_number}
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
);
