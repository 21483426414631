import { lazy } from "react";

// use lazy for better code splitting, a.k.a. load faster
const AinaDashboard = lazy(() => import("pages/ainainstitute/AinaDashboard"));
const AinaCourses = lazy(() => import("pages/ainainstitute/AinaCourses"));

const ainaRoutes = [
  {
    path: "/aina-institute/dashboard",
    component: AinaDashboard,
    title: "Aina Institute Dashboard",
  },
  {
    path: "/aina-institute/courses",
    component: AinaCourses,
    title: "Aina Institute Courses",
  },
];

export default ainaRoutes;

// export const publicRoutes = [
//   {
//     path: "/schools/:schoolSlug/preview",
//     component: SchoolPreview,
//     title: "School Preview",
//   },
//   {
//     path: "/our-schools",
//     component: OurSchools,
//     title: "Our Schools",
//   },
// ];
