import { lazy } from "react";

import SchoolAdminHOC from "routes/authGuards/SchoolAdminHOC";
import GradeAccessHOC from "routes/authGuards/GradeAccessHOC";

// use lazy for better code splitting, a.k.a. load faster
const ManageGrades = lazy(() => import("pages/grades/ManageGrades"));
const Classroom = lazy(() => import("pages/schools/student/Classroom"));
const GradeMisc = lazy(() => import("pages/grades/GradeMisc"));
const GradeClasses = lazy(() => import("pages/liveclass/GradeClasses"));
const GradeDiscussions = lazy(() =>
  import("pages/schools/student/GradeDiscussions")
);
const GradeChats = lazy(() => import("pages/schools/student/GradeChats"));
const GradeReporting = lazy(() => import("pages/reporting/GradeReporting"));
const ManageStaffReports = lazy(() =>
  import("pages/reporting/ManageStaffReports")
);
const StudentReports = lazy(() => import("pages/reporting/StudentReports"));
const ManageGradeReports = lazy(() =>
  import("pages/reporting/ManageGradeReports")
);
const GradeContents = lazy(() => import("pages/grades/GradeContents"));
const AcademicsPage = lazy(() => import("pages/grades/AcademicsPage"));

const gradeRoutes = [
  {
    path: "/schools/:slug/grades", // the url
    component: ManageGrades, // view rendered
    title: "Manage Grades",
    permission: ["Admin", "Group Manager", "SubGroup Manager"],
    authGuard: SchoolAdminHOC,
  },
  {
    path: "/:schoolSlug/:gradeSlug/classroom", // the url
    component: Classroom, // view rendered
    title: "Classroom",
    permission: ["Student", "Teacher", "Supervisor"],
    authGuard: GradeAccessHOC,
  },
  {
    path: "/:schoolSlug/:gradeSlug/live-classes", // the url
    component: GradeClasses, // view rendered
    title: "Live Classes",
    authGuard: GradeAccessHOC,
  },
  {
    path: "/:schoolSlug/:gradeSlug/discussions", // the url
    component: GradeDiscussions, // view rendered
    title: "Discussions",
    authGuard: GradeAccessHOC,
  },
  {
    path: "/:schoolSlug/:gradeSlug/chats", // the url
    component: GradeChats, // view rendered
    title: "Private Chats",
    authGuard: GradeAccessHOC,
  },

  {
    path: "/:schoolSlug/:gradeSlug/reporting", // the url
    component: GradeReporting, // view rendered
    title: "Reporting",
    permission: ["Teacher", "Supervisor"],
    authGuard: GradeAccessHOC,
  },

  {
    path: "/:schoolSlug/:gradeSlug/contents", // the url
    component: GradeContents, // view rendered
    title: "Grade Contents",
    authGuard: GradeAccessHOC,
  },

  {
    path: "/:schoolSlug/:gradeSlug/academics", // the url
    component: AcademicsPage, // view rendered
    title: "Academics Page",
    authGuard: GradeAccessHOC,
  },

  {
    path: "/schools/:schoolSlug/:gradeSlug/reports", // the url
    component: ManageGradeReports, // view rendered
    title: "Grade Reports",
    authGuard: SchoolAdminHOC,
  },

  {
    path: "/:schoolSlug/:gradeSlug/student-:enrollID/reports", // the url
    component: StudentReports, // view rendered
    title: "Student Reports",
    permission: [
      "Parent",
      "Teacher",
      "Supervisor",
      "Group Manager",
      "SubGroup Manager",
      "Admin",
    ],
    authGuard: GradeAccessHOC,
  },

  {
    path: "/schools/:schoolSlug/:gradeSlug/staff-:staffID/reports", // the url
    component: ManageStaffReports, // view rendered
    title: "Manage Staff Reports",
    // permission: ["Admin", "Group Manager", "SubGroup Manager"],
    authGuard: SchoolAdminHOC,
  },

  {
    path: "/schools/:schoolSlug/:slug/miscellaneous", // the url
    component: GradeMisc, // view rendered
    title: "Grade Miscellaneous",
    authGuard: GradeAccessHOC,
  },
];

export default gradeRoutes;
