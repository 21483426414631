import React from "react";

import svg404 from "images/svgs/404.svg";

const Page404 = () => {
  return (
    <div className="bg-gradient-to-br from-red-500 to-primary-500  relative overflow-hidden h-screen">
      <img
        src={svg404}
        className="absolute h-full w-full object-cover"
        alt="404 Not Found SVG"
      />
      <div className="inset-0 opacity-75 absolute"></div>
      <div className="container mx-auto px-6 md:px-12 relative z-10 flex items-center py-32 xl:py-40">
        <div className="w-full font-mono flex flex-col items-center relative z-10">
          {/* <h1 className="hidden sm:block font-extrabold text-5xl text-center text-white leading-tight mt-4">
            You are the only one here
          </h1> */}
          <p className="font-extrabold text-8xl my-44 text-white animate-pulse">
            Page Not Found
          </p>
        </div>
      </div>
    </div>
  );
};

export default Page404;
