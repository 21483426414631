import { lazy } from "react";

const CompanyDetails = lazy(() => import("pages/career/CompanyDetails"));
const JobDetails = lazy(() => import("pages/career/JobDetails"));
const ManageJobApplications = lazy(() =>
  import("pages/career/ManageJobApplications")
);

const careerRoutes = [
  {
    path: "/career/company-:companyId",
    component: CompanyDetails,
    title: "Company Details",
    roles: ["Admin", "Career Manager"],
  },
  {
    path: "/career/job-:jobId",
    component: JobDetails,
    title: "Job Details",
  },
  {
    path: "/career/job-:jobId/applications",
    component: ManageJobApplications,
    title: "Manage Job Applications",
    roles: ["Admin", "Career Manager", "Job Manager"],
  },
];

export default careerRoutes;
