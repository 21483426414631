import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

const initialState = false;

const loadingSlice = createSlice({
  name: "loading",
  initialState: initialState,
  reducers: {
    setLoading(state) {
      return true;
    },
    // setLoading: (state, action) => {
    //   if (typeof action.payload === "boolean") {
    //     return action.payload;
    //   }
    //   return state;
    // },
    resetLoading(state) {
      return false;
    },
  },
});

export default loadingSlice;

export const useLoading = () => {
  const loading = useSelector((state) => state.loading);
  return loading;
};
