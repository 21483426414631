import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import logo from "images/logo.png";
import { useInstitution } from "store/slices/institution";

const LogoBlock = ({ compact = false, isDashboardView = false }) => {
  const location = useLocation();
  const institution = useInstitution();

  // console.log(location);

  return institution ? (
    <>
      {location.pathname === "/" ? (
        <LogoLink compact={compact} />
      ) : (
        <div
          className={`flex w-max p-2.5", ${
            compact ? "items-center" : "items-end"
          }`}
        >
          {["holistic"].includes(institution) ? (
            <div
              className={`font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-primary-500 to-red-500 text-left ${
                isDashboardView ? "text-xl" : "text-2xl"
              }`}
            >
              Holistic {!isDashboardView && <br />} Education
            </div>
          ) : (
            <>
              <LogoLink compact={compact} />

              {/* {isDashboardView && ( */}
              <span
                className={`font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-primary-500 to-red-500 ${
                  compact ? "text-lg ml-1" : "text-xl"
                }`}
              >
                {institution} {institution === "aina" && "institute"}
              </span>
              {/* )} */}
            </>
          )}
        </div>
      )}
    </>
  ) : (
    <LogoLink />
  );
};

export default LogoBlock;

export const LogoLink = ({ compact }) => (
  <Link to="/">
    <img
      src={logo}
      className={`${compact ? "w-10" : "w-32"}`}
      alt="Original UED Logo"
    />
  </Link>
);
