import { lazy } from "react";

// use lazy for better code splitting, a.k.a. load faster
const PlatformManagers = lazy(() => import("pages/more/PlatformManagers"));
const CareerDashboard = lazy(() => import("pages/more/CareerDashboard"));

const moreRoutes = [
  {
    path: "/managers",
    component: PlatformManagers,
    title: "Platform Managers",
    permission: ["Admin"],
  },
  {
    path: "/careers",
    component: CareerDashboard,
    title: "Carrer",
  },
];

export default moreRoutes;

// export const publicRoutes = [
//   {
//     path: "/schools/:schoolSlug/preview",
//     component: SchoolPreview,
//     title: "School Preview",
//   },
// ];
