import { lazy } from "react";

import SchoolAccessHOC from "routes/authGuards/SchoolAccessHOC";
import GradeAccessHOC from "routes/authGuards/GradeAccessHOC";
import GradeAdminAccessHOC from "routes/authGuards/GradeAdminAccessHOC";
import SubjectAdminAccessHOC from "routes/authGuards/SubjectAdminAccessHOC";

// use lazy for better code splitting, a.k.a. load faster
const SchoolExam = lazy(() => import("pages/exam/SchoolExam"));
const ExamGrade = lazy(() => import("pages/exam/ExamGrade"));
const QuestionPaper = lazy(() => import("pages/exam/QuestionPaper"));
const GradeExam = lazy(() => import("pages/exam/GradeExam"));
const ViewSubjectPaper = lazy(() => import("pages/exam/ViewSubjectPaper"));
const AddAnswerPaper = lazy(() => import("pages/exam/AddAnswerPaper"));
const AnswerPaperSubmissions = lazy(() =>
  import("pages/exam/AnswerSubmissions")
);
const MarkAnswerPaper = lazy(() => import("pages/exam/MarkAnswerPaper"));

const examRoutes = [
  {
    path: "schools/:schoolSlug/exams",
    component: SchoolExam,
    title: "School Exam",
    authGuard: SchoolAccessHOC,
  },
  {
    path: ":schoolSlug/:gradeSlug/exam/",
    component: GradeExam,
    title: "Grade Exam",
    authGuard: GradeAccessHOC,
  },
  {
    path: ":schoolSlug/exam-:examID/:gradeSlug/:subjectSlug/paper-:paperID",
    component: ViewSubjectPaper,
    title: "Subject Paper",
    authGuard: GradeAccessHOC,
  },
  {
    path: ":schoolSlug/exam-:examID/:gradeSlug/:subjectSlug/paper-:paperID/answer",
    component: AddAnswerPaper,
    title: "Answer Paper",
    authGuard: GradeAccessHOC,
    permission: ["Student"],
  },
  {
    path: "schools/:schoolSlug/exam-:examID/:gradeSlug",
    component: ExamGrade,
    title: "Grade Papers",
    authGuard: GradeAccessHOC,
  },
  {
    path: "schools/:schoolSlug/exam-:examID/:gradeSlug/:subjectSlug/question-paper-:paperID",
    component: QuestionPaper,
    title: "Question Paper",
    authGuard: GradeAdminAccessHOC,
  },
  {
    path: "schools/:schoolSlug/exam-:examID/:gradeSlug/:subjectSlug/paper-:paperID/submissions/",
    component: AnswerPaperSubmissions,
    title: "Answer Paper Submissions",
    authGuard: SubjectAdminAccessHOC,
  },
  {
    path: "schools/:schoolSlug/exam-:examID/:gradeSlug/:subjectSlug/paper-:paperID/submission-:answerPaperID/",
    component: MarkAnswerPaper,
    title: "Mark Answer Paper",
    authGuard: SubjectAdminAccessHOC,
  },
];

export default examRoutes;
