import React from "react";
// import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

// import tw from "tailwind-styled-components";
import { Setting } from "@icon-park/react";

import T from "components/misc/Trans";
import logo from "images/logo.png";
// import LogoBlock from "./LogoBlock";
import { barItems } from "utils/data/sidebarItems";
import { useInstitution } from "store/slices/institution";
import { useCurrentUserRole } from "store/slices/auth";

// const AdminDiv = withAdminRole(tw.div``);

const USidebar = () => {
  const location = useLocation();
  const institution = useInstitution();
  const currentUserRole = useCurrentUserRole();
  // const currentUserID = useSelector((state) => state.auth.account.id);
  // const learningHistoryURL = `/my-learning-history`;

  return (
    <div
      id="sidebar"
      className="fixed sidebar min-h-screen w-[3.35rem] overflow-hidden border-r hover:w-56 hover:bg-white hover:shadow-lg z-30 transition-all transform duration-200 delay-100"
    >
      <div className="flex h-screen flex-col justify-between pt-2 pb-6">
        <div>
          {/* <LogoBlock isDashboardView /> */}
          <div className="flex items-end w-max p-2.5">
            <Link to="/">
              <img src={logo} className="w-28 ml-1" alt="UED Logo" />
            </Link>
            {institution && (
              <span
                className={`font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-primary-500 to-red-500 ${
                  institution === "aina" ? "text-sm -ml-1" : "text-lg"
                }`}
              >
                {institution === "aina" ? "aina institute" : institution}
              </span>
            )}
          </div>
          <ul className="mt-6 space-y-2 tracking-wide">
            {barItems.map((link, index) => {
              let linkURL =
                link.is_dynamic && institution
                  ? link.url[institution]
                  : link.url;
              let hasAccess = true;
              let showItem = true;
              let linkItem = (
                <li key={index} className="min-w-max">
                  <Link
                    to={linkURL}
                    aria-label="dashboard"
                    className={`relative flex items-center space-x-4 px-4 py-3 ${
                      location.pathname.includes(linkURL) &&
                      "bg-gradient-to-r from-red-500 to-primary-400  text-white"
                    }`}
                  >
                    {link.icon}
                    <span className="-mr-1 font-medium">
                      <T>{link.label}</T>
                    </span>
                  </Link>
                </li>
              );
              if (link.roles) {
                hasAccess = link.roles.includes(currentUserRole.title);
              }
              if (link.institutions) {
                showItem = link.institutions.includes(institution);
              }

              if (hasAccess && showItem) {
                return linkItem;
              } else {
                return null;
              }
            })}
            {/* Student only */}
            {/* {currentUserRole.title === "Student" && (
              <li className="min-w-max">
                <Link
                  to={learningHistoryURL}
                  aria-label="dashboard"
                  className={`relative flex items-center space-x-4 px-4 py-3 ${
                    location.pathname.includes(learningHistoryURL) &&
                    "bg-gradient-to-r from-red-500 to-primary-400  text-white"
                  }`}
                >
                  <History size={22} />
                  <span className="-mr-1 font-medium">Learning History</span>
                </Link>
              </li>
            )} */}
          </ul>
        </div>
        <div className="w-max -mb-3">
          <Link
            to="/settings"
            className="group flex items-center space-x-4 rounded-md px-4 py-3 text-gray-600"
          >
            <Setting theme="outline" size="22" fill="#333" />
            <span className="group-hover:text-gray-700">
              <T>Settings</T>
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default USidebar;
