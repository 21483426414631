import React from "react";

import tw from "tailwind-styled-components/";
import { useInstitution } from "store/slices/institution";

const withInstitution = (institutions) => (Component) => (props) => {
  const currentInstitution = useInstitution();
  // const hasAccess = userRole.some((element) => {
  //   return roles.includes(element);
  // });
  const hasAccess = institutions.includes(currentInstitution);
  if (hasAccess) {
    return <Component {...props} />;
  }
  return null;
};

export default withInstitution;

export const withSchool = withInstitution(["school"]);
export const withAinaInstitute = withInstitution(["aina"]);
export const withAcademy = withInstitution(["academy"]);
export const withHolistic = withInstitution(["holistic"]);

export const SchoolView = withSchool(tw.div``);
export const AinaView = withAinaInstitute(tw.div``);
export const AcademyView = withAcademy(tw.div``);
export const HolisticView = withHolistic(tw.div``);
