import { lazy } from "react";

const ArchiveCourses = lazy(() => import("pages/archive/ArchiveCourses"));

const archiveRoutes = [
  {
    path: "/archive",
    component: ArchiveCourses,
    title: "Archive",
  },
];

export default archiveRoutes;
