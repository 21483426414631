import React from "react";

import { Group } from "@mantine/core";

import T from "./Trans";
import noDataSVG from "images/svgs/nodata.svg";

const NotAvailable = ({
  label,
  showThumbnail = true,
  icon = null,
  shadowInner = false,
}) => {
  return (
    <div className="w-full py-6 flex flex-col justify-center items-center relative overflow-hidden sm:py-10">
      <div
        className={`relative px-6 bg-[#efedec] sm:max-w-lg sm:mx-auto rounded-xl sm:px-10 ${
          shadowInner ? "shadow-inner" : "shadow-xl pt-10  pb-8"
        }`}
      >
        <div className="max-w-md mx-auto">
          <div className="divide-y divide-gray-300/50">
            {showThumbnail && (
              <img src={noDataSVG} className="h-56" alt="Not Available SVG" />
            )}
            {icon && (
              <Group position="center" mb="sm">
                {icon}
              </Group>
            )}
            {label && (
              <>
                <div className="py-8 text-base flex justify-center">
                  <p className="text-xl text-center text-gray-900 opacity-75">
                    <T>{label}</T>
                  </p>
                </div>
                <div className="divide-y divide-gray-300/50"></div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotAvailable;
