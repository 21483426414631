import { lazy } from "react";

const CmsFeed = lazy(() => import("pages/cms/CmsFeed"));
const IndexPageSections = lazy(() => import("pages/cms/IndexPageSections"));
const FooterSection = lazy(() => import("pages/cms/FooterSection"));
const InstitutionPages = lazy(() =>
  import("pages/cms/institution/InstitutionPages")
);
const InstitutionCMS = lazy(() =>
  import("pages/cms/institution/InstitutionCMS")
);
const InstituteCMS = lazy(() => import("pages/cms/institution/InstituteCMS"));

const cmsRoutes = [
  {
    path: "/cms",
    component: CmsFeed,
    title: "Content Management System",
    permission: ["Admin"],
  },
  {
    path: "/cms/index-page",
    component: IndexPageSections,
    title: "Index Page Sections",
    permission: ["Admin"],
  },
  {
    path: "/cms/footer-section",
    component: FooterSection,
    title: "CMS Footer",
    permission: ["Admin"],
  },
  {
    path: "/cms/institution-pages",
    component: InstitutionPages,
    title: "Institution Pages",
    permission: ["Admin"],
  },
  {
    path: "/cms/:groupSlug/:page",
    component: InstitutionCMS,
    title: "Institution CMS",
    permission: ["Admin"],
  },
  {
    path: "/cms/:groupSlug/:subgroupSlug/:page",
    component: InstituteCMS,
    title: "Institute CMS",
    permission: ["Admin"],
  },
];

export default cmsRoutes;
