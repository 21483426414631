import React from "react";

import LogoBlock from "./LogoBlock";

const UFooter = () => {
  return (
    <footer className="w-full bg-gray-50 mt-auto">
      <div className="max-w-screen-xl px-4 py-8 mx-auto sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <LogoBlock compact isDashboardView />

          <p className="mt-4 text-sm text-center text-gray-500 lg:text-right lg:mt-0">
            Copyright &copy; 2022. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default UFooter;
