import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import toast from "react-hot-toast";
import { Popover, Tooltip, ColorSwatch } from "@mantine/core";
import { FaSchool, FaReadme, FaCheckCircle } from "react-icons/fa";
import { FaHandsHelping, FaUniversity, FaHands } from "react-icons/fa";
import { MdHomeWork } from "react-icons/md";
import { HiAcademicCap } from "react-icons/hi";
import { FcOk } from "react-icons/fc";

import LoadingDots from "components/misc/LoadingDots";
import institutionSlice from "store/slices/institution";
import axiosService from "utils/axios";
import { institutePaths } from "pages/platform/PlatformSelect";
import { useInstitution } from "store/slices/institution";

// const dashboardPaths = {
//   aina: "/courses",
//   academy: "/courses",
//   all: "/courses",
//   school: "/schools/dashboard",
//   // university: "/ued-university",
//   holistic: "/courses",
// };

const InstitutionControl = () => {
  const institution = useInstitution();
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    setVisible(!visible);
  };

  return (
    <>
      <Popover
        opened={visible}
        onClose={() => setVisible(false)}
        width={300}
        position="bottom"
        withArrow
      >
        <Popover.Target>
          <button
            // ref={setReferenceElement}
            onClick={() => setVisible((m) => !m)}
            className={
              "flex items-center border-black border-2 py-1.5 px-3 hover:bg-primary-300 lg:shadow-[3px_3px_0_0_#000] mr-3 mb-3 sm:mb-0"
            }
          >
            <div className={"group relative px-1 text-base font-bold"}>
              <MdHomeWork className="h-5 w-5 m-auto text-gray-600" />
            </div>
            <span className="font-bold">Institutions</span>
            {/* {institution && (
              <span className="font-bold">
                {institution} {institution === "aina" && "institute"}
              </span> 
            )} */}
          </button>
        </Popover.Target>
        <Popover.Dropdown>
          <p className="text-left text-gray-600 font-medium text-sm">
            Select Institution
          </p>
          <InstitutionSelect alt current={institution} toggle={toggleVisible} />
        </Popover.Dropdown>
      </Popover>
    </>
  );
};

export default InstitutionControl;

export const InstitutionSelect = ({ current, alt = false, toggle = null }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // console.log(location);

  const institutions = [
    {
      id: "school",
      label: "Schools",
      icon: FaSchool,
    },
    {
      id: "aina",
      label: "Aina Institute",
      icon: FaReadme,
    },
    {
      id: "academy",
      label: "Academy",
      icon: HiAcademicCap,
    },

    {
      id: "university",
      label: "University",
      icon: FaUniversity,
    },

    {
      id: "all",
      label: "UED For All",
      icon: FaHandsHelping,
    },

    {
      id: "holistic",
      label: "Holistic Education",
      icon: FaHands,
    },
  ];

  const selectInstitution = (id) => {
    if (id === current) return;
    setLoading(true);
    let url = "/utility/set-institution/";
    let data = {
      institution: id,
    };
    axiosService
      .post(url, data)
      .then(() => {
        dispatch(institutionSlice.actions.setInstitution({ id }));
        toast.success("Institution Selected!");
        if (alt) {
          navigate(institutePaths[id]);
        } else {
          navigate(
            ["aina", "all", "academy"].includes(id) ? "/courses" : "/dashboard"
          );
          // if (dashboardPaths.hasOwnProperty(id)) {
          //   navigate(dashboardPaths[id]);
          // }
        }
        toggle && toggle();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        toast.error("Unable to set institution!");
      });
  };

  return (
    <>
      {loading && <LoadingDots visible />}
      <div
        className={`h-full ${
          alt ? "" : "grid md:grid-cols-2 xl:grid-cols-3 md:gap-x-6"
        }`}
      >
        {institutions.map((item) => (
          <div
            key={item.id}
            onClick={() => selectInstitution(item.id)}
            className={`flex w-full rounded-lg overflow-hidden mx-auto transition-all transform duration-200 cursor-pointer ${
              alt ? "group hover:bg-gradient-vertical" : "p-4 hover:scale-105"
            }`}
          >
            <div
              className={`relative flex items-center rounded-2xl w-full hover:shadow ${
                alt
                  ? "flex-row justify-start px-2"
                  : "flex-col justify-center p-4"
              }`}
              style={{ transform: "translate(0px, 0px)", opacity: 1 }}
            >
              {!alt && (
                <>
                  <div
                    className="absolute z-0 w-full h-full text-white transform scale-x-105 scale-y-95 bg-red-300 rounded-xl -rotate-2"
                    style={{ zIndex: -1 }}
                  ></div>
                  <div
                    className="absolute z-0 w-full h-full text-white transform scale-x-105 scale-y-95 bg-red-400 rounded-xl rotate-2 "
                    style={{ zIndex: -1 }}
                  ></div>
                  <div
                    className="absolute z-0 w-full h-full transform scale-x-105 scale-y-95 bg-white rounded-xl "
                    style={{ zIndex: -1 }}
                  ></div>
                </>
              )}
              <Tooltip
                label={
                  <span className="capitalize">
                    {current === item.id ? "Active" : "Not active"}
                  </span>
                }
                className={alt && current !== item.id && "hidden"}
              >
                <ColorSwatch
                  component="button"
                  color={current === item.id ? "green" : "gray"}
                  className="text-white"
                >
                  {current === item.id ? (
                    <FcOk size="20" />
                  ) : (
                    <FaCheckCircle size="20" />
                  )}
                </ColorSwatch>
              </Tooltip>
              <h3
                className={`z-10 p-2 font-extrabold text-red fancy-text ${
                  alt
                    ? "sm:text-lg group-hover:text-white"
                    : "text-2xl sm:text-3xl"
                }`}
              >
                {item.label}
              </h3>
              {!alt && (
                <div className="z-10 p-2 text-red fancy-icon">
                  <item.icon fontSize={100} />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
