import { lazy } from "react";

import SchoolAccessHOC from "routes/authGuards/SchoolAccessHOC";

// use lazy for better code splitting, a.k.a. load faster
const SchoolFinanceDept = lazy(() => import("pages/finance/SchoolFinanceDept"));

const financeRoutes = [
  {
    path: "/schools/:slug/finance",
    component: SchoolFinanceDept,
    title: "Finance Department",
    authGuard: SchoolAccessHOC,
  },
];

export default financeRoutes;

// export const publicRoutes = [
//   {
//     path: "/schools/:schoolSlug/preview",
//     component: SchoolPreview,
//     title: "School Preview",
//   },
// ];
