import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import * as Yup from "yup";
import { Formik } from "formik";
import toast from "react-hot-toast";
import { PasswordInput, Button } from "@mantine/core";
import { BiReset } from "react-icons/bi";

import LoadingDots from "components/misc/LoadingDots";
import axiosService from "utils/axios";
import { Error } from "components/misc/Utility";
import { InputWrap } from "components/misc/Inputs";

export const getCharacterValidationError = (str) => {
  return `Your password must have at least 1 ${str} character`;
};

const ResetPassword = ({ token }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const initialValues = {
    password: "",
    confirm_password: "",
  };

  const validation = Yup.object({
    password: Yup.string()
      .required("Please enter a password")
      // check minimum characters
      .min(8, "Password must have at least 8 characters")
      // different error messages for different requirements
      .matches(/[0-9]/, getCharacterValidationError("digit"))
      .matches(/[a-z]/, getCharacterValidationError("lowercase"))
      .matches(/[A-Z]/, getCharacterValidationError("uppercase")),
    confirm_password: Yup.string()
      .required("Please re-type your password")
      // use oneOf to match one of the values inside the array.
      // use "ref" to get the value of passwrod.
      .oneOf([Yup.ref("password")], "Passwords does not match"),
  });

  const confirmReset = (values) => {
    setLoading(true);
    let url = "/password-reset/confirm/";
    let data = { password: values.password, token };

    axiosService
      .post(url, data)
      .then((res) => {
        // console.log(res);
        if (res.data.status === "OK") {
          toast.success("Password reset success!");
          navigate("/login");
        } else {
          setLoading(false);
          toast.error("Unknown error!");
        }
      })
      .catch(() => {
        setLoading(false);
        toast.error("Error while confirming password reset!");
      });
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={(values, { resetForm }) => {
        confirmReset(values);
        resetForm();
      }}
    >
      {(formik) => {
        const { values, handleChange, handleBlur, handleSubmit } = formik;
        return (
          <form onSubmit={handleSubmit} className="relative mt-3">
            {loading && <LoadingDots visible />}
            <div className="flex flex-col space-y-5">
              <InputWrap label="New Password">
                <PasswordInput
                  variant="unstyled"
                  size="md"
                  name="password"
                  placeholder="Strong Password"
                  autoComplete="new-password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Error name="password" />
              </InputWrap>

              <InputWrap label="Confirm Password">
                <PasswordInput
                  variant="unstyled"
                  size="md"
                  name="confirm_password"
                  placeholder="Strong Password"
                  autoComplete="confirm-password"
                  value={values.confirm_password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Error name="confirm_password" />
              </InputWrap>

              <Button
                type="submit"
                size="md"
                variant="light"
                color="orange"
                className="text-white hover:bg-red bg-orange-500"
                rightIcon={<BiReset />}
              >
                Confirm Reset
              </Button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default ResetPassword;
