import React from "react";
import { useSelector } from "react-redux";

import tw from "tailwind-styled-components/";

const withRole = (roles) => (Component) => (props) => {
  const userRole = useSelector((state) => state.auth.role);

  // const hasAccess = userRole.some((element) => {
  //   return roles.includes(element);
  // });
  const hasAccess = roles.includes(userRole.title);
  if (hasAccess) {
    return <Component {...props} />;
  }
  return null;
};

export default withRole;

export const withAdminRole = withRole(["Admin"]);
export const withStudentRole = withRole(["Student"]);
export const withCareerAdminRole = withRole(["Admin", "Career Manager"]);
export const withCareerManagerRole = withRole(["Career Manager"]);
export const withJobManagerRole = withRole(["Job Manager"]);
export const withJobAdminRole = withRole([
  "Admin",
  "Career Manager",
  "Job Manager",
]);
export const withParentRole = withRole(["Parent"]);
export const withSupervisorRole = withRole(["Supervisor"]);
export const withTeacherRole = withRole(["Teacher"]);
export const withStaffRole = withRole(["Staff"]);
export const withGradeStaffRole = withRole(["Supervisor", "Teacher"]);
export const withGradeAdminRole = withRole([
  "Admin",
  "Group Manager",
  "SubGroup Manager",
  "Supervisor",
  "Teacher",
]);
export const withManagementRole = withRole([
  "Admin",
  "Group Manager",
  "SubGroup Manager",
]);
export const withManagerRole = withRole(["Group Manager", "SubGroup Manager"]);
export const withCourseAdminRole = withRole([
  "Admin",
  "Group Manager",
  "SubGroup Manager",
  "Supervisor",
]);
export const withLibraryManagerRole = withRole(["Library Manager"]);
export const withLibraryAdminRole = withRole([
  "Admin",
  "Group Manager",
  "SubGroup Manager",
  "Library Manager",
]);
// An HOC to handle user role based UI components 👆

export const AdminView = withAdminRole(tw.div``);
export const ManagementView = withManagementRole(tw.div``);
export const SchoolAdminView = withManagementRole(tw.div``);
export const GradeAdminView = withGradeAdminRole(tw.div``);
export const StudentView = withStudentRole(tw.div``);
export const TeacherView = withTeacherRole(tw.div``);
export const SupervisorView = withSupervisorRole(tw.div``);
export const CourseAdminView = withCourseAdminRole(tw.div``);
export const ManagerView = withManagerRole(tw.div``);
export const LibraryManagerView = withLibraryManagerRole(tw.div``);
export const LibraryAdminView = withLibraryAdminRole(tw.div``);
export const CareerAdminView = withCareerAdminRole(tw.div``);
export const CareerManagerView = withCareerManagerRole(tw.div``);
export const JobManagerView = withJobManagerRole(tw.div``);
