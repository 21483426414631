import toast from "react-hot-toast";
import axiosService from "./axios";
import { createSyntheticFile } from "@dropzone-ui/react";

const dictToFormData = (dict_data) => {
  let formdata = new FormData();

  for (var key in dict_data) {
    formdata.append(key, dict_data[key]);
  }

  return formdata;
};

const viewFormData = (formdata) => {
  for (var pair of formdata.entries()) {
    console.log(pair[0] + ", " + pair[1]);
  }
};

const addUserMeta = (formdata, type = "update") => {
  const rootState = JSON.parse(localStorage.getItem("persist:root"));
  const authState = JSON.parse(rootState.auth);
  // console.log(authState);
  const currentUserID = authState.account.id ?? null;

  if (type === "create") {
    formdata.append("created_by", currentUserID);
  }
  formdata.append("last_modified_by", currentUserID);
  return formdata;
};

const addUserMetaDict = (dict, type = "update") => {
  const rootState = JSON.parse(localStorage.getItem("persist:root"));
  const authState = JSON.parse(rootState.auth);
  // console.log(authState);
  const currentUserID = authState.account.id ?? null;

  if (type === "create") {
    dict.created_by = currentUserID;
  }
  dict.last_modified_by = currentUserID;
  return dict;
};

const redirectToLogin = (current_url) => {
  sessionStorage.setItem("url_to_load", current_url);
  window.location.href = "/legacy/login";
};

const prepareAPIURL = (apiURL) => {
  return apiURL?.includes("/ued/rest/v1")
    ? `${apiURL?.replace("/ued/rest/v1", "")}?schools=true`
    : `${apiURL}?schools=true`;
};

export const prepareApiUrl = (apiURL) => {
  return apiURL?.includes("/ued/rest/v1")
    ? `${apiURL?.replace("/ued/rest/v1", "")}`
    : `${apiURL}`;
};

const prepareAttachmentPreview = (attachment) => {
  attachment.file_item = {};
  if (attachment?.file_type) {
    attachment.file_item = {
      id: Math.floor(1000 + Math.random() * 9000),
      file: createSyntheticFile("", 0, attachment.file_type),
      valid: true,
    };
  }

  return attachment;
};

const authorizeZoomApp = () => {
  let connectZoomURL = `https://zoom.us/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_ZOOM_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_ZOOM_REDIRECT_URL}`;
  window.open(connectZoomURL, "_blank");
};

const validateZoomAccess = () => {
  axiosService.post("/accounts/validate-zoom-access/", {}).catch((err) => {
    console.log(err);
    toast.error("Unable to validate Zoom access!");
  });
};

const preventDefault = (e) => {
  e.preventDefault();
};

export {
  dictToFormData,
  viewFormData,
  addUserMeta,
  addUserMetaDict,
  redirectToLogin,
  prepareAPIURL,
  prepareAttachmentPreview,
  authorizeZoomApp,
  validateZoomAccess,
  preventDefault,
};
