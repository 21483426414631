import { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";

import { Toaster } from "react-hot-toast";
import { Group, Loader } from "@mantine/core";

import "./App.css";
import Login from "pages/auth/Login";
import Signup from "pages/auth/Signup";
import publicRoutes from "routes/public";
import protectedRoutes from "routes/protected";
import BaseLayout from "layouts/Base";
import Dashboard from "layouts/Dashboard";
import Page404 from "pages/public/404";
import ForgotPassword from "pages/auth/ForgotPassword";
import ConfirmPasswordReset from "pages/auth/ConfirmPasswordReset";

const ZoomClient = lazy(() => import("pages/liveclass/ZoomClient"));

function App() {
  return (
    <div className="App">
      <Toaster />
      <Suspense
        fallback={
          <Group className="w-full h-screen" position="center">
            <div>
              <Loader variant="dots" color="red" />
            </div>
          </Group>
        }
      >
        <Routes>
          {publicRoutes.map((route, index) => (
            <Route
              exact
              key={index}
              path={route.path}
              element={
                <BaseLayout
                  component={route.component}
                  docTitle={route.title}
                />
              }
            />
          ))}

          {protectedRoutes.map((route, index) => (
            <Route
              exact
              key={index}
              path={route.path}
              element={
                <Dashboard
                  component={route.component}
                  docTitle={route.title}
                  permissions={route?.permission}
                  authGuard={route?.authGuard}
                />
              }
            />
          ))}

          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/reset-password" element={<ForgotPassword />} />
          <Route
            path="/accounts/password/reset/confirm/:uid/:token"
            element={<ConfirmPasswordReset />}
          />

          <Route
            exact
            path="/:courseSlug/live-session-:sessionID/zoom-client"
            element={<ZoomClient />}
          />

          <Route
            exact
            path="/:schoolSlug/club-:clubID/event-:eventID/live-session-:sessionID/zoom-client"
            element={<ZoomClient />}
          />

          <Route path="*" element={<Page404 />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
