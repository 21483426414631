import React from "react";
import { useLayoutEffect, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, Navigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import Head from "./blocks/UHead";
import Sidebar from "./blocks/USidebar";
import Footer from "./blocks/UFooter";
import useDocTitle from "utils/useDocTitle";
import axiosService from "utils/axios";
import authSlice from "store/slices/auth";
import { useAuthenticated, useCurrentUserRole } from "store/slices/auth";

const Dashboard = ({
  component: Component,
  docTitle,
  permissions,
  authGuard: AuthGuard = null,
  // ...rest
}) => {
  const currentUserData = useSelector((state) => state.auth.account);
  const currentUserRole = useCurrentUserRole();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hasAccess, setHasAccess] = useState(true);
  const userAuthenticated = useAuthenticated();
  const { t, i18n } = useTranslation();

  useLayoutEffect(() => {
    const force_reload = sessionStorage.getItem("force_reload");
    if (force_reload) {
      window.location.reload();
      sessionStorage.removeItem("force_reload");
    }
  });

  useLayoutEffect(() => {
    // maybe we need to remove this after the role transition is complete
    if (typeof currentUserRole == "string" || !currentUserRole) {
      dispatch(authSlice.actions.logout());
      navigate("/login", { replace: true });
    }
  });

  useEffect(() => {
    // Updating the user data if it has been tampered or edited manually somehow
    axiosService
      .get("/accounts/validate-logged-in-user/")
      .then((res) => {
        const validUserData = res.data;
        try {
          if (
            JSON.stringify(validUserData) !== JSON.stringify(currentUserData)
          ) {
            // console.log("User data Tampered ❎");
            dispatch(authSlice.actions.setAccount(validUserData));
            // console.log("Valid Data Updated 🚀");
          }
        } catch (error) {
          setHasAccess(false);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(authSlice.actions.logout());
          navigate("/login");
        }
      });
  });

  let title = "UED";
  if (docTitle) {
    // console.log(i18n.exists(docTitle));
    if (i18n.exists(docTitle)) {
      docTitle = t(docTitle);
    }
    title = `UED | ${docTitle}`;
  }
  useDocTitle(title);

  // console.log(AuthGuard);

  return userAuthenticated && hasAccess ? (
    AuthGuard ? (
      <AuthGuard>
        <DashboardLayout permissions={permissions}>
          <Component />
        </DashboardLayout>
      </AuthGuard>
    ) : (
      <DashboardLayout permissions={permissions}>
        <Component />
      </DashboardLayout>
    )
  ) : (
    <NavigateToHome />
  );
};

export default Dashboard;

const DashboardLayout = ({ children, permissions }) => {
  const currentUserRole = useCurrentUserRole();
  // console.log(permissions, currentUserRole);

  return permissions && !permissions.includes(currentUserRole.title) ? (
    <NavigateToHome />
  ) : (
    <div className="relative min-h-screen">
      <Sidebar />

      <div
        id="dashboard-body"
        className="w-[calc(100%-3.35rem)] ml-auto min-h-screen flex flex-col"
      >
        <Head />
        <div className="flex-1 bg-gray-100">{children}</div>
        <Footer />
      </div>
    </div>
  );
};

export const NavigateToHome = () => {
  const location = useLocation();
  const userAuthenticated = useAuthenticated();

  return (
    <Navigate
      to={{
        pathname: userAuthenticated ? "/home" : "/login",
        state: {
          from: location.pathname,
        },
      }}
    />
  );
};
